
import { Vue, Options } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import { BookingCommissionController } from "@/app/ui/controllers/BookingCommissionControllers";
import {
  TIER_BASE_OPTIONS,
  PRICE_TIER_REFERENCE_OPTIONS,
  PRICE_REFERENCE_OPTIONS,
  COMMISSION_QUANTIFIER_OPTIONS,
  COMMISSION_TYPE_OPTIONS
} from "@/app/ui/views/commission-configuration/booking-commission/BookingCommissionUtils";

@Options({
  components: {
    Title
  },
  props: {
    formType: {
      default: "add",
      type: String
    }
  }
})
export default class BookingCommissionFormCommissionRules extends Vue {
  props: any = this.$props;
  get form() {
    return BookingCommissionController.bookingCommissionForm;
  }

  // tier base
  get tierBaseOptions() {
    return TIER_BASE_OPTIONS;
  }
  isOpenSelectTierBase = false;
  onOpenSelectTierBase() {
    this.isOpenSelectTierBase = true;
  }
  onCloseSelectTierBase() {
    this.isOpenSelectTierBase = false;
  }
  onSelectTierBase(name: string, value: string) {
    this.form.commissionTierBase = value;
    if (value === "chargeable_weight" || value === "") {
      this.form.commissionPriceTierReference = "";
    }
    this.onCloseSelectTierBase();
  }

  commissionSourceFor(item: any) {
    if (
        this.form.commissionBookingSource === "client" &&
        this.form.commissionFor === "manual"
      ) {
        return item.value !== "retail";
      }
      if (
        this.form.commissionBookingSource === "pos" &&
        this.form.commissionFor === "manual"
      ) {
        return item.value !== "client";
      }
      return true;
  }

  // price tier reference
  get priceTierReferenceOptions() {
    return PRICE_TIER_REFERENCE_OPTIONS.filter((item: any) => this.commissionSourceFor(item));
  }
  isOpenSelectPriceTierReference = false;
  onOpenSelectPriceTierReference() {
    this.isOpenSelectPriceTierReference = true;
  }
  onCloseSelectPriceTierReference() {
    this.isOpenSelectPriceTierReference = false;
  }
  onSelectPriceTierReference(name: string, value: string) {
    this.form.commissionPriceTierReference = value;
    this.onCloseSelectPriceTierReference();
  }
  get isPriceTierReferenceMandatory() {
    return this.form.commissionTierBase === "total_base_rate";
  }

  // commission price reference
  get priceCommissionReferenceOptions() {
    return PRICE_REFERENCE_OPTIONS.filter((item: any) => this.commissionSourceFor(item));
  }
  isOpenSelectPriceCommissionReference = false;
  onOpenSelectPriceCommissionReference() {
    this.isOpenSelectPriceCommissionReference = true;
  }
  onCloseSelectPriceCommissionReference() {
    this.isOpenSelectPriceCommissionReference = false;
  }
  onSelectPriceCommissionReference(name: string, value: string) {
    this.form.commissionPriceReference = value;
    this.onCloseSelectPriceCommissionReference();
  }

  // commission type
  get commissionTypeOptions() {
    return COMMISSION_TYPE_OPTIONS;
  }
  isOpenSelectCommissionType = false;
  onOpenSelectCommissionType() {
    this.isOpenSelectCommissionType = true;
  }
  onCloseSelectCommissionType() {
    this.isOpenSelectCommissionType = false;
  }
  onSelectCommissionType(name: string, value: string) {
    this.form.commissionType = value;
    if (value === "per_kg" || value === "flat" || value === "") {
      this.form.commissionPriceReference = "";
      this.form.commissionQuantifier = "";
    }
    this.onCloseSelectCommissionType();
  }

  // commission quantifier
  get commissionQuantifierOptions() {
    return COMMISSION_QUANTIFIER_OPTIONS;
  }
  isOpenSelectCommissionQuantifier = false;
  onOpenSelectCommissionQuantifier() {
    this.isOpenSelectCommissionQuantifier = true;
  }
  onCloseSelectCommissionQuantifier() {
    this.isOpenSelectCommissionQuantifier = false;
  }
  onSelectCommissionQuantifier(name: string, value: string) {
    this.form.commissionQuantifier = value;
    this.onCloseSelectCommissionQuantifier();
  }
  get isCommissionQuantifierAndPriceReferenceMandatory() {
    return this.form.commissionType === "percentage";
  }
}
