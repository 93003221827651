/* eslint-disable @typescript-eslint/camelcase */
import { DeliveryTieringPolicyRequest } from "../contracts/DeliveryTieringPolicyRequest";

export class EditDeliveryTieringPolicy implements DeliveryTieringPolicyRequest {
  dtpol_product_id: number;
  description: string;
  commissions: DeliveryTieringPolicyCommissions[];
  constructor(
    dtpol_product_id: number,
    description: string,
    commissions: DeliveryTieringPolicyCommissions[]
  ) {
    this.dtpol_product_id = dtpol_product_id;
    this.description = description;
    this.commissions = commissions;
  }

  toJSON(): string {
    return JSON.stringify({
      dtpol_product_id: this.dtpol_product_id,
      description: this.description,
      commissions: this.commissions
    });
  }
}

export class DeliveryTieringPolicyCommissions {
  tier: number;
  publish_rate: number;
  shipping_surcharge: number;
  cod: number;
  constructor(
    tier: number,
    publish_rate: number,
    shipping_surcharge: number,
    cod: number
  ) {
    this.tier = tier;
    this.publish_rate = publish_rate;
    this.shipping_surcharge = shipping_surcharge;
    this.cod = cod;
  }
}
