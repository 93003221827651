
import { Vue, Options } from "vue-class-component";
import CreateUpdate from "@/app/ui/components/data-wrapper/create-update.vue";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import { BOOKING_COMMISSION } from "@/app/infrastructures/misc/RolePermission";
import { BookingCommissionController } from "@/app/ui/controllers/BookingCommissionControllers";
import BookingCommissionDetailCriteriaBooking from "@/app/ui/views/commission-configuration/booking-commission/booking-commission-detail/booking-commission-detail-criteria-booking.vue";
import BookingCommissionDetailCommissionRules from "@/app/ui/views/commission-configuration/booking-commission/booking-commission-detail/booking-commission-detail-commission-rules.vue";
import BookingCommissionDetailTierCalculation from "@/app/ui/views/commission-configuration/booking-commission/booking-commission-detail/booking-commission-detail-tier-calculation.vue";
import { checkRolePermission } from "@/app/infrastructures/misc/Utils";

@Options({
  components: {
    BookingCommissionDetailTierCalculation,
    BookingCommissionDetailCommissionRules,
    BookingCommissionDetailCriteriaBooking,
    CreateUpdate,
    DetailLayout
  }
})
export default class BookingCommissionDetail extends Vue {
  mounted() {
    this.getDetail();
  }

  get isAbleToEdit() {
    return checkRolePermission(BOOKING_COMMISSION.EDIT);
  }

  get isLoading() {
    return BookingCommissionController.isLoading;
  }
  get errorCause() {
    return BookingCommissionController.errorCause;
  }
  get isError() {
    return BookingCommissionController.isError;
  }

  getDetail() {
    BookingCommissionController.fetchBookingCommissionDetail({
      id: this.$route.params.id,
      type: "detail"
    });
  }
  get detailData() {
    return BookingCommissionController.bookingCommissionDetail;
  }
  get title() {
    return `ID: ${this.detailData.commissionId}`;
  }

  goBack() {
    this.$router.push("/tarif/commission-configuration/booking");
  }
  goToEdit() {
    this.$router.push(
      `/tarif/commission-configuration/booking/${this.$route.params.id}/edit`
    );
  }
}
