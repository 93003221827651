
/* eslint-disable @typescript-eslint/camelcase */
import { Vue, Options } from "vue-class-component";
import {
  checkRolePermission,
  downloadFile,
  formatDateWithoutTime
} from "@/app/infrastructures/misc/Utils";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { BOOKING_COMMISSION } from "@/app/infrastructures/misc/RolePermission";
import { BulkBookingCommissionData } from "@/domain/entities/BulkBookingCommission";
import { BulkBookingCommissionController } from "../../../controllers/BulkBookingCommissionController";
import EmptyState from "@/app/ui/components/empty-list/index.vue";
import router from "../../../router";
import { ConfigBucketStorage } from "@/feature-flags/flags-config-bucket-storage";

@Options({
  components: {
    EmptyState
  },
  computed: {
    getTitle() {
      return this.$route.meta.name;
    },
    ableToCreate() {
      return checkRolePermission(BOOKING_COMMISSION.CREATE);
    },
    ableToViewDetail() {
      return checkRolePermission(BOOKING_COMMISSION.DETAIL);
    }
  },
  directives: {
    "click-outside": {
      beforeMount: function(el, binding) {
        const ourClickEventHandler = (event: any) => {
          if (!el.contains(event.target) && el !== event.target) {
            binding.value(event);
          }
        };
        el.__vueClickEventHandler__ = ourClickEventHandler;
        document.addEventListener("click", ourClickEventHandler);
      },
      unmounted: function(el) {
        document.removeEventListener("click", el.__vueClickEventHandler__);
      }
    }
  }
})
export default class BulkBookingCommission extends Vue {
  mounted() {
    BulkBookingCommissionController.initBulkBookingCommissionList();
    this.fetchBulkBookingCommissionList();
  }

  fetchBulkBookingCommissionList() {
    BulkBookingCommissionController.getBulkBookingCommissionList();
  }

  get bulkBookingCommissionList() {
    return BulkBookingCommissionController.bulkBookingCommissionList.data;
  }

  get isLoading() {
    return BulkBookingCommissionController.isLoading;
  }

  get errorCause() {
    return BulkBookingCommissionController.errorCause;
  }

  goToCreate() {
    router.push("/tarif/commission-configuration/bulk-booking/create");
  }

  get account() {
    return AccountController.accountData;
  }

  get columns() {
    return [
      {
        name: "ID",
        styleHead: "w-36 text-left whitespace-no-wrap align-top",
        render: (item: BulkBookingCommissionData) => {
          return `<div class="overflow-ellipsis text-left">${item.uploadId}</div>`;
        }
      },
      {
        name: "Nama Aktivitas",
        styleHead: "w-64 text-left whitespace-no-wrap align-top",
        render: (item: BulkBookingCommissionData) => {
          return `<div class="capitalize overflow-ellipsis text-left">${item.activityName}</div>`;
        }
      },
      {
        name: "Tipe Berkas",
        styleHead: "w-64 text-left whitespace-no-wrap align-top",
        render: (item: BulkBookingCommissionData) => {
          return `<div class="capitalize overflow-ellipsis text-left">${item.type}</div>`;
        }
      },
      {
        name: "Tanggal Dibuat",
        styleHead: "w-40 text-left whitespace-no-wrap align-top",
        render: (item: BulkBookingCommissionData) => {
          return `<div class="flex flex-col"><div class="overflow-ellipsis text-left">${formatDateWithoutTime(
            item.createdAt
          )}</div>
          <div class="truncate text-gray-lp-600 w-32">${
            item.createdBy
          }</div></div>`;
        }
      },
      {
        name: "Status",
        styleHead: "w-36 text-left whitespace-no-wrap align-top",
        render: (item: BulkBookingCommissionData) => {
          return `<div class="capitalize overflow-ellipsis text-left flex flex-row gap-x-1 font-medium ${this.convertLabelStatus(item.status.toLowerCase())}">
            <img class="w-3 max-w-none" src="${this.convertIcons(item.status.toLowerCase())}" />
            ${item.status}
          </div>`;
        }
      },
      {
        name: "Lampiran",
        key: "download_column",
        styleHead: "w-64 text-left whitespace-no-wrap align-top",
        downloadList: (item: any) => {
          return [
            {
              name: "Original",
              url: item.successFile,
              fileName: item.successFileName
            },
            {
              name: "Error",
              url: item.failedFile,
              fileName: item.failedFileName
            }
          ];
        }
      }
    ];
  }

  convertLabelStatus(status: string) {
    const statuses: any = {
      "success": "text-green-lp-100",
      "failed": "text-red-lp-750",
      "partially": "text-yellow-lp-100"
    }

    return statuses[status];
  }

  convertIcons(status: string) {
    return require(`@/app/ui/assets/svg/${status}-status.svg`)
  }

  get pagination() {
    return BulkBookingCommissionController.bulkBookingCommissionList.pagination;
  }

  onClickRow(item: BulkBookingCommissionData) {
    router.push(`/tarif/commission-configuration/bulk-booking/${item.id}`);
  }

  // Filter Status
  statusName = "";
  statusData = [
    { name: "Semua Status", value: "" },
    { name: "Success", value: "success" },
    { name: "Failed", value: "failed" },
    { name: "Partially", value: "partially" }
  ];

  statusSelect = false;

  onOpenStatusSelect() {
    this.statusSelect = true;
  }

  onCloseStatusSelect() {
    this.statusSelect = false;
  }
  onSelectStatus(name: string, value: string) {
    this.statusName = name;
    this.onCloseStatusSelect();
    BulkBookingCommissionController.setStatus(value);
    this.fetchBulkBookingCommissionList();
  }

  downloadTemplate() {
    this.download(
      `${ConfigBucketStorage.config_bucket_dev_genesis_announcement.getValue()}/template-bulk-booking-commission.csv`,
      "template-bulk-booking-commission.csv"
    );
  }
  // download file
  download(url: string, fileName: string) {
    const newFileName = fileName
      .split(".")
      .slice(0, -1)
      .join(".");
    downloadFile(url, newFileName, ".csv");
  }
}
