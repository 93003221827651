
import { Vue, Options } from "vue-class-component";
import { BulkBookingCommissionController } from "@/app/ui/controllers/BulkBookingCommissionController";
import CustomDropdown from "@/app/ui/components/custom-dropdown/index.vue";
import ShipmentIdSelect from "./shipment-id-select.vue";
import UploadFile from "@/app/ui/components/upload-file/index.vue";
import {
  commaDecimalToDecimal,
  formatInputMoneyWithDecimal
} from "@/app/infrastructures/misc/Utils";
import { BulkBookingController } from "@/app/ui/controllers/BulkBookingController";
import {
  BulkBookingCommissionCreate,
  TierCalculation
} from "@/data/payload/api/BulkBookingCommissionApiRequest";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { ModalMessageEntities } from "@/domain/entities/MainApp";
import {
  COMMISSION_FOR_OPTIONS,
  COMMISSION_FOR_CLIENT_OPTIONS,
  PRICE_TIER_REFERENCE_OPTIONS,
  PRICE_REFERENCE_OPTIONS
} from "@/app/ui/views/commission-configuration/booking-commission/BookingCommissionUtils";

@Options({
  components: {
    CustomDropdown,
    ShipmentIdSelect,
    UploadFile
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (this.answerLeavingPage) {
      next();
    } else {
      this.leavePageConfirmation = true;
      this.nextPath = to.path;
      next(false);
    }
  }
})
export default class Add extends Vue {
  mounted() {
    BulkBookingCommissionController.resetForm();
  }

  get form() {
    return BulkBookingCommissionController.form;
  }

  isFocused = false;

  get payload() {
    return {
      ...this.form,
      tierCalculation: this.form.tierCalculation.map(item => ({
        from: commaDecimalToDecimal(item.from.replace(/\./g, "")),
        to: commaDecimalToDecimal(item.to.replace(/\./g, "")),
        commission: commaDecimalToDecimal(item.commission.replace(/\./g, "")),
        commissionBranch: commaDecimalToDecimal(item.commissionBranch.replace(/\./g, "")),
        commissionDipickup: commaDecimalToDecimal(item.commissionDipickup.replace(/\./g, "")),
        commissionPickup: commaDecimalToDecimal(item.commissionPickup.replace(/\./g, "")),
      }))
    };
  }

  get isCommissionPriceTierReferenceValid() {
    if (this.form.tierBase.value === "chargeable_weight") {
      return true;
    }
    return this.form.tierReferencePrice.value;
  }
  get isCommissionQuantifierAndPriceReferenceValid() {
    if (
      this.form.commissionType.value === "per_kg" ||
      this.form.commissionType.value === "flat"
    ) {
      return true;
    }
    return (
      this.form.quantifier.value && this.form.commissionReferencePrice.value
    );
  }
  get isFormValid() {
    return (
      this.payload.activityName &&
      this.payload.bookingSource.name &&
      this.payload.appliedTo.value &&
      (this.payload.bookingSource.value === "pos" &&
      this.payload.appliedTo.value === "shipment"
        ? this.payload.shipmentId.find(item => item.name && item.value)
        : true) &&
      this.payload.tierBase.name &&
      this.isCommissionPriceTierReferenceValid &&
      this.isCommissionQuantifierAndPriceReferenceValid &&
      this.payload.commissionType.name &&
      !this.payload.tierCalculation.find(
        (item, index) =>
          //validasi max 1.000.000.000
          item.from > 1000000000 ||
          !item.to ||
          item.to > 1000000000 ||
          //validasi max 100 for percentage
          (this.form.commissionType.value === "percentage" &&
            item.commission > 100) ||
          (this.form.commissionType.value === "percentage" &&
            item.commissionBranch > 100) ||
          (this.form.commissionType.value === "percentage" &&
            item.commissionDipickup > 100) ||
          (this.form.commissionType.value === "percentage" &&
            item.commissionPickup > 100) ||

          //validasi max 1.000.000 for flat & Perkg
          (this.form.commissionType.value !== "percentage" &&
            item.commission > 1000000) ||
          (this.form.commissionType.value !== "percentage" &&
            item.commissionBranch > 1000000) ||
          (this.form.commissionType.value !== "percentage" &&
            item.commissionDipickup > 1000000) ||
          (this.form.commissionType.value !== "percentage" &&
            item.commissionPickup > 1000000) ||
          
          //validation start must be higher than tier end n-1
          (index > 0 &&
            item.from <= this.payload.tierCalculation[index - 1].to) ||
          //validation end must be higher than start
          item.to <= item.from
      ) &&
      !this.form.tierCalculation.find(item => !item.commission || !item.from) &&
      !this.form.tierCalculation.find(item => !item.commissionBranch || !item.from) &&
      !this.form.tierCalculation.find(item => !item.commissionDipickup || !item.from) &&
      !this.form.tierCalculation.find(item => !item.commissionPickup || !item.from) &&
      this.payload.file
    );
  }

  formatDecimal(value: string) {
    return formatInputMoneyWithDecimal(value, 2);
  }

  // booking source
  optionBookingSource = [
    { name: "POS", value: "pos" },
    { name: "Client", value: "client" }
  ];
  selectBookingSource(item: any) {
    if (this.form.bookingSource.name === item.name) {
      this.form.bookingSource = { name: "", value: "" };
    } else {
      this.form.bookingSource = item;
    }
    this.form.appliedTo = { name: "", value: "" };
  }

  // commission for
  get optionAppliedTo() {
    if (this.form.bookingSource.name === "POS") {
      return COMMISSION_FOR_OPTIONS;
    } else {
      return COMMISSION_FOR_CLIENT_OPTIONS;
    }
  }
  selectAppliedTo(item: any) {
    if (this.form.appliedTo.name === item.name) {
      this.form.appliedTo = { name: "", value: "" };
    } else {
      this.form.appliedTo = item;
    }
  }

  // tier base
  optionTierBase = [
    { name: "Total Base Rate", value: "total_base_rate" },
    { name: "Chargeable Weight", value: "chargeable_weight" }
  ];
  selectTierBase(item: any) {
    if (this.form.tierBase.name === item.name) {
      this.form.tierBase = { name: "", value: "" };
    } else {
      this.form.tierBase = item;
    }
    this.form.tierReferencePrice = { name: "", value: "" };
  }

  commissionSourceFor(item: any) {
    if (
        this.form.bookingSource.value === "client" &&
        this.form.appliedTo.value === "manual"
      ) {
        return item.value !== "retail";
      }
      if (
        this.form.bookingSource.value === "pos" &&
        this.form.appliedTo.value === "manual"
      ) {
        return item.value !== "client";
      }
      return true;
  }

  // tier reference price
  get optionTierReferencePrice() {
    return PRICE_TIER_REFERENCE_OPTIONS.filter((item: any) => this.commissionSourceFor(item));
  }
  selectTierReferencePrice(item: any) {
    if (this.form.tierReferencePrice.name === item.name) {
      this.form.tierReferencePrice = { name: "", value: "" };
    } else {
      this.form.tierReferencePrice = item;
    }
  }
  get isPriceTierReferenceMandatory() {
    return this.form.tierBase.value === "total_base_rate";
  }

  // commission reference price
  get optionCommissionReferencePrice() {
    return PRICE_REFERENCE_OPTIONS.filter((item: any) => this.commissionSourceFor(item));
  }
  selectCommissionReferencePrice(item: any) {
    if (this.form.commissionReferencePrice.name === item.name) {
      this.form.commissionReferencePrice = { name: "", value: "" };
    } else {
      this.form.commissionReferencePrice = item;
    }
  }

  // commission type
  optionCommissionType = [
    { name: "Persentase", value: "percentage" },
    { name: "Perkg", value: "per_kg" },
    { name: "Flat", value: "flat" }
  ];
  selectCommissionType(item: any) {
    if (this.form.commissionType.name === item.name) {
      this.form.commissionType = { name: "", value: "" };
    } else {
      this.form.commissionType = item;
    }
    this.form.commissionReferencePrice = { name: "", value: "" };
    this.form.quantifier = { name: "", value: "" };
  }

  //calculation
  optionQuantifier = [
    { name: "Publish Rate", value: "publish_rate" },
    {
      name: "Publish Rate + Shipping Surcharge",
      value: "publish_rate,shipping_surcharge"
    },
    {
      name: "Publish Rate + Shipping Surcharge + Destination Forward Rate",
      value: "publish_rate,shipping_surcharge,destination_forward_rate"
    },
    {
      name:
        "Publish Rate + Shipping Surcharge + Origin Forward Rate + Destination Forward Rate",
      value:
        "publish_rate,shipping_surcharge,origin_forward_rate,destination_forward_rate"
    }
  ];
  selectQuantifier(item: any) {
    if (this.form.quantifier.name === item.name) {
      this.form.quantifier = { name: "", value: "" };
    } else {
      this.form.quantifier = item;
    }
  }
  get isCommissionQuantifierAndPriceReferenceMandatory() {
    return this.form.commissionType.value === "percentage";
  }

  // upload button
  async onSave() {
    this.handleConfirmationSave(false);
    const res = await BulkBookingCommissionController.createBulkBookingCommission(
      new BulkBookingCommissionCreate(
        this.payload.tierBase.value,
        this.payload.tierReferencePrice.value,
        this.payload.commissionReferencePrice.value,
        this.payload.commissionType.value,
        this.payload.quantifier.value,
        this.payload.bookingSource.value,
        this.payload.appliedTo.value,
        this.payload.shipmentId.map(item => item.value).join(","),
        JSON.stringify(
          this.payload.tierCalculation.map(
            item => new TierCalculation(item.from, item.to, item.commission, item.commissionBranch, item.commissionDipickup, item.commissionPickup)
          )
        ),
        this.payload.description,
        this.payload.activityName,
        this.payload.file
      )
    );
    const responseCreate = res && res.data;
    if (res && responseCreate) {
      this.modalSuccess(responseCreate);
    }
  }

  modalSuccess(responseCreate: any) {
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        title: responseCreate.successRow
          ? "Pengunggahan Berhasil !"
          : "Pengunggahan Gagal !",
        message: this.messageModal(responseCreate.successRow, responseCreate.failedRow),
        textSuccess: responseCreate.failedRow ? "Download" : "OK",
        textCancel: responseCreate.failedRow ? "OK" : "",
        onSubmit: () =>
          responseCreate.failedRow
            ? this.downloadFailed(responseCreate.bulkId)
            : this.onSubmitSuccessModal(),
        onClose: () =>
          responseCreate.failedRow && this.onSubmitSuccessModal(),
        totalSuccess: responseCreate.successRow,
        totalFailed: responseCreate.successRow && responseCreate.failedRow,
        image: this.iconMessageModal(responseCreate.successRow, responseCreate.failedRow),
        customWidth:
          responseCreate.successRow && responseCreate.failedRow
            ? "402px"
            : "380px",
        isBulkUpload: true
      })
    );
  }

  messageModal(successRow: boolean, failedRow: boolean) {
    if (successRow && failedRow) {
      return "Beberapa perhitungan komisi pada berkas berhasil<br />diunggah dan dibuat, namun terdapat kegagalan<br />pada beberapa perhitungan. Silahkan download<br />untuk mengatahui kesalahan.";
    }

    return successRow
      ? "Berkas “Booking Commission” berhasil diunggah. Pembuatan konfigurasi komisi booking juga berhasil disimpan."
      : "Perhitungan komisi pada berkas gagal diunggah dan dibuat. Silahkan download untuk mengatahui kesalahan.";
  }

  iconMessageModal(successRow: boolean, failedRow: boolean) {
    if (successRow && failedRow) {
      return "image-modal-warning";
    }

    return successRow
      ? "image-modal-success"
      : "image-modal-failed";
  }

  onSubmitSuccessModal() {
    MainAppController.closeMessageModal();
    this.answerLeavingPage = true;
    this.goBack();
  }

  // leave page confirmation
  leavePageConfirmation = false;
  nextPath = "";
  answerLeavingPage = false;
  onSubmitLeavePage() {
    this.answerLeavingPage = true;
    this.$router.push(this.nextPath);
  }

  onCloseLeavePage() {
    this.leavePageConfirmation = false;
  }

  openConfirmationSave = false;
  handleConfirmationSave(val: boolean) {
    if (val && this.isFormValid) {
      this.openConfirmationSave = true;
      return;
    }
    this.openConfirmationSave = false;
  }

  goBack() {
    this.$router.push("/tarif/commission-configuration/bulk-booking");
  }

  downloadFailed(id: number) {
    BulkBookingController.downloadFailedFile(id); //example
  }

  get isShipmentIdMandatory() {
    return (
      this.form.bookingSource.value === "pos" &&
      this.form.appliedTo.value === "shipment"
    );
  }
}
