
import { Options, Vue } from "vue-class-component";
import CustomDropdown from "@/app/ui/components/custom-dropdown/index.vue";
import RadioButton from "primevue/radiobutton";
import { ShipmentPrefix } from "@/domain/entities/ShipmentBooking";
import { BookingCommissionController } from "@/app/ui/controllers/BookingCommissionControllers";

@Options({
  emits: ["change", "update:modelValue"],
  components: {
    CustomDropdown,
    RadioButton
  },
  props: {
    modelValue: {
      default: [],
      type: Array
    },
    disabled: {
      default: false,
      type: Boolean
    }
  }
})
export default class ComponentSelectShipmentId extends Vue {
  props: any = this.$props;
  isExpand = true;

  radioValue = "";
  onChangeRadioValue() {
    if (this.radioValue === "ALL") {
      this.$emit("update:modelValue", [
        {
          name: "All",
          prefix: "ALL"
        }
      ]);
    }
    if (this.radioValue === "CUSTOM") {
      this.$emit("update:modelValue", []);
    }
  }

  shipmentIdOptions: ShipmentPrefix[] = [];
  shipmentIdLoading = false;
  async fetchShipmentId() {
    this.shipmentIdLoading = true;
    this.shipmentIdOptions = await BookingCommissionController.fetchListShipmentPrefix();
    this.shipmentIdLoading = false;
  }

  removeSelect(item: any) {
    const indexItemSelected = this.props.modelValue.findIndex((data: any) => {
      return data["value"] ? data["value"] === item["value"] : data === item;
    });
    this.props.modelValue.splice(indexItemSelected, 1);
  }
}
