
import { Options, Vue } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import {
  CommissionsDtpol,
  DeliveryTieringPolicyData
} from "@/domain/entities/DeliveryTieringPolicy";
import { DeliveryTieringPolicyController } from "@/app/ui/controllers/DeliveryTieringPolicyController";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import {
  checkRolePermission,
  convertDecimalWithComma,
  formatDate
} from "@/app/infrastructures/misc/Utils";
import { DTPOL_CONFIG } from "@/app/infrastructures/misc/RolePermission";

@Options({
  components: {
    DetailLayout,
    Title
  }
})
export default class DetailPage extends Vue {
  mounted() {
    this.fetchDetailData();
  }

  get ableToEdit() {
    return checkRolePermission(DTPOL_CONFIG.EDIT);
  }

  get id(): any {
    return this.$route.params.id;
  }

  fetchDetailData() {
    DeliveryTieringPolicyController.getDetail(this.id);
  }

  get detailData(): DeliveryTieringPolicyData {
    return DeliveryTieringPolicyController.detailData;
  }

  get isLoading() {
    return DeliveryTieringPolicyController.isLoading;
  }

  get errorCause() {
    return DeliveryTieringPolicyController.errorCause;
  }

  // table
  columns = [
    {
      name: "Tier",
      styleHead: "w-2/20 text-center whitespace-no-wrap",
      styleCustom: "justify-center",
      render: (item: CommissionsDtpol) =>
        `<div class="justify-center font-normal text-black-lp-300 whitespace-no-wrap">${item.tier}</div>`
    },
    {
      name: "Publish Rate",
      styleHead: "w-6/20 text-center whitespace-no-wrap",
      styleCustom: "justify-center",
      render: (item: CommissionsDtpol) =>
        `<p class="font-normal text-black-lp-300 whitespace-no-wrap">${convertDecimalWithComma(
          item.publishRateCommission,
          2
        )}%</p>`
    },
    {
      name: "Shipping Surcharge",
      styleHead: "w-6/20 text-center whitespace-no-wrap",
      styleCustom: "justify-center",
      render: (item: CommissionsDtpol) =>
        `<p class="font-normal text-black-lp-300 whitespace-no-wrap">${convertDecimalWithComma(
          item.shippingSurchargeCommission,
          2
        )}%</p>`
    },
    {
      name: "Nilai Barang COD",
      styleHead: "w-6/20 text-center whitespace-no-wrap",
      styleCustom: "justify-center",
      render: (item: CommissionsDtpol) =>
        `<p class="font-normal text-black-lp-300 whitespace-no-wrap">${convertDecimalWithComma(
          item.codCommission,
          2
        )}%</p>`
    }
  ];

  pagination = {
    page: 1,
    limit: 100
  };

  // route navigatiion
  goBack() {
    this.$router.push(
      "/tarif/commission-configuration/delivery-tiering-policy"
    );
  }

  goToEdit() {
    this.$router.push(
      `/tarif/commission-configuration/delivery-tiering-policy/${this.id}/edit`
    );
  }

  onChipStatus(status: string) {
    let chip = "";
    switch (status) {
      case "pending":
        chip =
          "bg-gray-lp-200 text-black-lp-300 rounded-full px-2 text-12px xxl:text-14px";
        break;
      case "verified":
        chip =
          "bg-yellow-lp-200 text-yellow-lp-100 rounded-full px-2 text-12px xxl:text-14px";
        break;
      case "invalid":
        chip =
          "bg-red-lp-600 text-red-lp-500 rounded-full px-2 text-12px xxl:text-14px";
        break;
      case "active":
        chip =
          "bg-green-lp-300 text-green-lp-200 rounded-full px-2 text-12px xxl:text-14px";
        break;
      default:
        break;
    }
    return chip;
  }

  formatDateWithoutTime(date: string) {
    return formatDate(date);
  }
}
