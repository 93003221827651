
import { Vue, Options } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import { BookingCommissionController } from "@/app/ui/controllers/BookingCommissionControllers";
import {
  COMMISSION_BOOKING_SOURCE,
  COMMISSION_FOR_CLIENT_OPTIONS,
  COMMISSION_FOR_OPTIONS
} from "@/app/ui/views/commission-configuration/booking-commission/BookingCommissionUtils";

@Options({
  components: {
    Title
  }
})
export default class BookingCommissionDetailCriteriaBooking extends Vue {
  get detailData() {
    return BookingCommissionController.bookingCommissionDetail;
  }
  get commissionShipmentId() {
    if (this.detailData.commissionShipmentId.length === 0) {
      return "-";
    }
    return this.detailData.commissionShipmentId
      .map((item: any) => {
        if (item.name && item.prefix) {
          return `${item.name} (${item.prefix})`;
        }
        return "-";
      })
      .join("<br>");
  }
  get commissionBookingSource() {
    if (!this.detailData.commissionBookingSource) {
      return "";
    }
    return COMMISSION_BOOKING_SOURCE.find((item: any) => {
      return item.value == this.detailData.commissionBookingSource;
    })?.name;
  }
  get commissionFor() {
    if (!this.detailData.commissionFor) {
      return "";
    }
    const options =
      this.detailData.commissionBookingSource === "pos"
        ? COMMISSION_FOR_OPTIONS
        : COMMISSION_FOR_CLIENT_OPTIONS;
    return options.find((item: any) => {
      return item.value == this.detailData.commissionFor;
    })?.name;
  }
}
