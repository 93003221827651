
import { Vue, Options } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import { BulkBookingCommissionController } from "@/app/ui/controllers/BulkBookingCommissionController";
import {
  COMMISSION_QUANTIFIER_OPTIONS,
  COMMISSION_TYPE_OPTIONS,
  PRICE_REFERENCE_OPTIONS,
  PRICE_TIER_REFERENCE_OPTIONS,
  TIER_BASE_OPTIONS
} from "@/app/ui/views/commission-configuration/booking-commission/BookingCommissionUtils";

@Options({
  components: {
    Title
  }
})
export default class DetailCommissionRules extends Vue {
  get detailData() {
    return BulkBookingCommissionController.bulkBookingCommissionDetail;
  }

  get commissionTierBase() {
    if (!this.detailData.commissionTierBase) {
      return "";
    }
    return TIER_BASE_OPTIONS.find((item: any) => {
      return item.value == this.detailData.commissionTierBase;
    })?.name;
  }

  get commissionPriceTierReference() {
    if (!this.detailData.commissionPriceTierReference) {
      return "";
    }
    return PRICE_TIER_REFERENCE_OPTIONS.find((item: any) => {
      return item.value == this.detailData.commissionPriceTierReference;
    })?.name;
  }

  get commissionPriceReference() {
    if (!this.detailData.commissionPriceReference) {
      return "";
    }
    return PRICE_REFERENCE_OPTIONS.find((item: any) => {
      return item.value == this.detailData.commissionPriceReference;
    })?.name;
  }

  get commissionType() {
    if (!this.detailData.commissionType) {
      return "";
    }
    return COMMISSION_TYPE_OPTIONS.find((item: any) => {
      return item.value == this.detailData.commissionType;
    })?.name;
  }

  get commissionQuantifier() {
    if (!this.detailData.commissionQuantifier) {
      return "";
    }
    return COMMISSION_QUANTIFIER_OPTIONS.find((item: any) => {
      return item.value == this.detailData.commissionQuantifier;
    })?.name;
  }
}
