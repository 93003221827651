
/* eslint-disable @typescript-eslint/camelcase */
import { Vue, Options } from "vue-class-component";
import { DeliveryTieringPolicyData } from "@/domain/entities/DeliveryTieringPolicy";
import EmptyState from "@/app/ui/components/empty-list/index.vue";
import {
  checkRolePermission,
  ellipsisString,
  formatDateWithoutTime
} from "@/app/infrastructures/misc/Utils";
import { DeliveryTieringPolicyController } from "@/app/ui/controllers/DeliveryTieringPolicyController";
import { DTPOL_CONFIG } from "@/app/infrastructures/misc/RolePermission";

@Options({
  components: {
    EmptyState
  }
})
export default class Cargo extends Vue {
  mounted() {
    this.fetchDeliveryTieringPolicyList();
  }

  get ableToViewDetail() {
    return checkRolePermission(DTPOL_CONFIG.DETAIL);
  }

  fetchDeliveryTieringPolicyList() {
    DeliveryTieringPolicyController.fetchList();
  }

  get deliveryTieringPolicyData() {
    return DeliveryTieringPolicyController.listData.data;
  }

  get isLoading() {
    return DeliveryTieringPolicyController.isLoading;
  }

  get columns() {
    return [
      {
        name: "Produk",
        styleHead: "w-1/12 text-left whitespace-no-wrap align-top",
        render: (item: DeliveryTieringPolicyData) => {
          return `<div class="text-black-lp-300 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                      ${item.product}
                  </div>
                </div>`;
        }
      },
      {
        name: "Deskripsi",
        styleHead: "w-9/12 text-left whitespace-no-wrap align-top",
        render: (item: DeliveryTieringPolicyData) => {
          return `<div class="overflow-ellipsis text-left">${ellipsisString(
            item.description,
            100
          )}</div>`;
        }
      },
      {
        name: "Tanggal Diubah",
        styleHead: "w-2/12 text-left whitespace-no-wrap align-top",
        render: (item: DeliveryTieringPolicyData) => {
          return `<div class="flex flex-col"><div class="overflow-ellipsis text-left">${formatDateWithoutTime(
            item.updatedAt
          )}<div class="truncate text-gray-lp-600 w-32">${
            item.updatedBy
          }</div></div>`;
        }
      }
    ];
  }

  get pagination() {
    return DeliveryTieringPolicyController.listData.pagination;
  }

  onClickRow(item: DeliveryTieringPolicyData) {
    this.$router.push(
      `/tarif/commission-configuration/delivery-tiering-policy/${item.id}?params=${item.product}`
    );
  }

  get errorCause(): string {
    return DeliveryTieringPolicyController.errorCause;
  }
}
