import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "relative bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_TableV2 = _resolveComponent("TableV2")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.deliveryTieringPolicyData.length === 0 && !_ctx.errorCause && !_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_EmptyState, {
          key: 0,
          customMessage: "Oops.. Belum ada data yang dapat ditampilkan"
        }))
      : (_openBlock(), _createBlock(_component_TableV2, {
          key: 1,
          "border-bottom": false,
          "border-top": false,
          columns: _ctx.columns,
          data: _ctx.deliveryTieringPolicyData,
          loading: _ctx.isLoading,
          onRequest: _ctx.fetchDeliveryTieringPolicyList,
          isDetailAble: _ctx.ableToViewDetail,
          pagination: _ctx.pagination,
          onClick: _ctx.onClickRow,
          errorCause: _ctx.errorCause,
          onTryAgain: _ctx.fetchDeliveryTieringPolicyList
        }, null, 8, ["columns", "data", "loading", "onRequest", "isDetailAble", "pagination", "onClick", "errorCause", "onTryAgain"]))
  ]))
}