
import { Options, Vue } from "vue-class-component";
import CustomDropdown from "@/app/ui/components/custom-dropdown/index.vue";
import RadioButton from "primevue/radiobutton";
import { ShipmentPrefix } from "@/domain/entities/ShipmentBooking";
import { BookingCommissionController } from "@/app/ui/controllers/BookingCommissionControllers";

@Options({
  emits: ["change", "update:modelValue"],
  components: {
    CustomDropdown,
    RadioButton
  },
  props: {
    placeholder: {
      default: "Pilih shipment ID",
      type: String
    },
    modelValue: {
      default: [],
      required: true
    }
  }
})
export default class ShipmentIdSelect extends Vue {
  props: any = this.$props;

  selectShipmentIdOpen = true;
  selectedValueName = "";

  // shipment id
  get optionShipmentId() {
    return this.shipmentIdOptions.map((item: ShipmentPrefix) => {
      return {
        name: item.name,
        value: item.prefix
      };
    });
  }
  shipmentIdLoading = false;
  shipmentIdOptions: ShipmentPrefix[] = [];
  async fetchShipmentId() {
    this.shipmentIdLoading = true;
    this.shipmentIdOptions = await BookingCommissionController.fetchListShipmentPrefix();
    this.shipmentIdLoading = false;
  }

  //radio
  radioValue = "";
  selectedShipmentId: Array<{ name: string; value: string }> = [];

  get watchRadioValueChanges() {
    const radioValue = this.radioValue;
    if (radioValue === "All") {
      this.selectedShipmentId = [...this.optionShipmentId];
      const allValue = [{ name: "All", value: "all" }];
      this.$emit("update:modelValue", allValue);
    } else {
      this.selectedShipmentId = [];
      this.$emit("update:modelValue", this.selectedShipmentId);
    }
    return "";
  }

  removeChoice(item: Array<{ name: string; value: string }>) {
    if (item.length === 0) {
      this.radioValue = "";
    }
  }

  onSelectCustom(item: Array<{ name: string; value: string }>) {
    this.$emit("update:modelValue", item);
  }
}
