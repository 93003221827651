
import { Vue, Options } from "vue-class-component";
import { DeliveryTieringPolicyController } from "@/app/ui/controllers/DeliveryTieringPolicyController";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import {
  formatDecimal,
  commaDecimalToDecimal
} from "@/app/infrastructures/misc/Utils";
import {
  DeliveryTieringPolicyCommissions,
  EditDeliveryTieringPolicy
} from "@/data/payload/api/DeliveryTieringPolicyApiRequest";

@Options({
  components: {
    DetailLayout
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (this.answerLeavingPage) {
      next();
    } else {
      this.leavePageConfirmation = true;
      this.nextPath = to.path;
      next(false);
    }
  }
})
export default class EditDeliveryTieringPolicyPage extends Vue {
  mounted() {
    this.fetchDetailData();
  }

  fetchDetailData() {
    DeliveryTieringPolicyController.getDetail(this.dtpolId);
  }

  get detailData() {
    return DeliveryTieringPolicyController.editDetailData;
  }

  get dtpolId() {
    return parseInt(`${this.$route.params.id}`);
  }

  get isFormValid() {
    return (
      this.detailData.description &&
      !this.detailData.commissions.find(item => {
        return (
          !`${item.publishRateCommission}` ||
          !`${item.shippingSurchargeCommission}` ||
          !`${item.codCommission}`
        );
      }) &&
      !this.commissionCalculationError.find(item => {
        return (
          item.publishRateCommission ||
          item.shippingSurchargeCommission ||
          item.codCommission
        );
      })
    );
  }

  get payload() {
    return {
      ...this.detailData,
      commissions: this.detailData.commissions.map(item => ({
        ...item,
        publishRateCommission: commaDecimalToDecimal(
          `${item.publishRateCommission}`
        ),
        shippingSurchargeCommission: commaDecimalToDecimal(
          `${item.shippingSurchargeCommission}`
        ),
        codCommission: commaDecimalToDecimal(`${item.codCommission}`)
      }))
    };
  }

  get commissionCalculationError() {
    return this.payload.commissions.map(item => ({
      publishRateCommission: item.publishRateCommission > 100,
      shippingSurchargeCommission: item.shippingSurchargeCommission > 100,
      codCommission: item.codCommission > 100
    }));
  }

  formatDecimal(val: string) {
    return formatDecimal(val);
  }

  get isLoading() {
    return DeliveryTieringPolicyController.isLoading;
  }

  get isError() {
    return DeliveryTieringPolicyController.isError;
  }

  get errorCause() {
    return DeliveryTieringPolicyController.errorCause;
  }

  leavePageConfirmation = false;
  saveConfirmation = false;
  get successSave() {
    return DeliveryTieringPolicyController.isSuccessEdit;
  }
  nextPath = "";
  answerLeavingPage = false;
  onLeavePage() {
    this.leavePageConfirmation = false;
    this.answerLeavingPage = true;
    this.$router.push(this.nextPath);
  }
  closeSaveConfirmation() {
    this.saveConfirmation = false;
  }
  closeSuccessSave() {
    DeliveryTieringPolicyController.setSuccessEdit(false);
    this.answerLeavingPage = true;
    this.goBack();
  }

  onCloseLeavePage() {
    this.leavePageConfirmation = false;
  }

  onSaveForm() {
    if (!this.isFormValid) {
      return;
    }
    this.saveConfirmation = true;
  }

  saveEdit() {
    this.saveConfirmation = false;
    DeliveryTieringPolicyController.editDtpol(
      new EditDeliveryTieringPolicy(
        this.payload.id,
        this.payload.description,
        this.payload.commissions.map(
          item =>
            new DeliveryTieringPolicyCommissions(
              item.tier,
              item.publishRateCommission,
              item.shippingSurchargeCommission,
              item.codCommission
            )
        )
      )
    );
  }

  goBack() {
    this.$router.push(
      `/tarif/commission-configuration/delivery-tiering-policy/${this.dtpolId}`
    );
  }
}
