
/* eslint-disable @typescript-eslint/camelcase */
import { Vue, Options } from "vue-class-component";
import {
  checkRolePermission,
  formatDateWithoutTime
} from "@/app/infrastructures/misc/Utils";
import { BOOKING_COMMISSION } from "@/app/infrastructures/misc/RolePermission";
import { BookingCommissionData } from "@/domain/entities/BookingCommission";
import { BookingCommissionController } from "@/app/ui/controllers/BookingCommissionControllers";
import debounce from "lodash/debounce";
import EmptyState from "@/app/ui/components/empty-list/index.vue";
import router from "../../../router";
import {
  COMMISSION_BOOKING_SOURCE,
  COMMISSION_FOR_CLIENT_OPTIONS,
  COMMISSION_FOR_OPTIONS
} from "@/app/ui/views/commission-configuration/booking-commission/BookingCommissionUtils";

@Options({
  components: {
    EmptyState
  },
  computed: {
    getTitle() {
      return this.$route.meta.name;
    },
    ableToCreate() {
      return checkRolePermission(BOOKING_COMMISSION.CREATE);
    },
    ableToViewDetail() {
      return checkRolePermission(BOOKING_COMMISSION.DETAIL);
    }
  },
  directives: {
    "click-outside": {
      beforeMount: function(el, binding) {
        const ourClickEventHandler = (event: any) => {
          if (!el.contains(event.target) && el !== event.target) {
            binding.value(event);
          }
        };
        el.__vueClickEventHandler__ = ourClickEventHandler;
        document.addEventListener("click", ourClickEventHandler);
      },
      unmounted: function(el) {
        document.removeEventListener("click", el.__vueClickEventHandler__);
      }
    }
  }
})
export default class Cargo extends Vue {
  mounted() {
    BookingCommissionController.initBookingCommissionList();
    this.fetchBookingCommissionList();
  }

  fetchBookingCommissionList() {
    BookingCommissionController.getBookingCommissionList();
  }

  get bookingCommissionList() {
    return BookingCommissionController.bookingCommissionList.data;
  }

  get isError() {
    return BookingCommissionController.isError;
  }

  get isLoading() {
    return BookingCommissionController.isLoading;
  }

  get errorCause() {
    return BookingCommissionController.errorCause;
  }

  get pagination() {
    return BookingCommissionController.bookingCommissionList.pagination;
  }

  onRequest() {
    BookingCommissionController.getBookingCommissionList();
  }

  goToCreate() {
    router.push("/tarif/commission-configuration/booking/create");
  }

  get columns() {
    return [
      {
        name: "ID Config.",
        styleHead: "w-20 text-left whitespace-no-wrap align-top",
        render: (item: BookingCommissionData) => {
          return `<div class="overflow-ellipsis text-left">${item.bcId}</div>`;
        }
      },
      {
        name: "Kode",
        styleHead: "w-24 text-left whitespace-no-wrap align-top",
        render: (item: BookingCommissionData) => {
          return `<div class="overflow-ellipsis text-left">${item.bcCode}</div>`;
        }
      },
      {
        name: "Sumber Booking",
        styleHead: "w-40 text-left whitespace-no-wrap align-top",
        render: (item: BookingCommissionData) => {
          return `<div class="overflow-ellipsis text-left">${this.commissionBookingSource(
            item.bcBookingSource
          )}</div>`;
        }
      },
      {
        name: "Komisi Untuk",
        styleHead: "w-40 text-left whitespace-no-wrap align-top",
        render: (item: BookingCommissionData) => {
          return `<div class="overflow-ellipsis text-left">${this.commissionFor(
            item.bcBookingSource,
            item.bcAppliedTo
          )}</div>`;
        }
      },
      {
        name: "Shipment Prefix",
        styleHead: "w-40 text-left whitespace-no-wrap align-top",
        render: (item: BookingCommissionData) => {
          return `<div class="overflow-ellipsis text-left">${item.bcShipmentPrefix}</div>`;
        }
      },
      {
        name: "Status Komisi",
        key: "status",
        styleHead: "w-24 text-left align-top",
        styleBody: "",
        render: (item: BookingCommissionData) => {
          return `
        <div class="flex">
      <div
        class="rounded-full px-2 py-0 ${
          item.bcDetailStatus.toLowerCase() === "active"
            ? "bg-green-lp-300 text-green-lp-200"
            : "bg-red-300 text-red-lp-200"
        }"
      ><span class="capitalize">${item.bcDetailStatus.toLowerCase()}</span></div></div>`;
        }
      },
      {
        name: "Kota Asal",
        styleHead: "w-40 text-left whitespace-no-wrap align-top",
        render: (item: BookingCommissionData) => {
          return `<div class="overflow-ellipsis text-left">${item.bcDetailOriginCityName}</div>`;
        }
      },
      {
        name: "Kota Tujuan",
        styleHead: "w-40 text-left whitespace-no-wrap align-top",
        render: (item: BookingCommissionData) => {
          return `<div class="overflow-ellipsis text-left">${item.bcDetailDestinationCityName}</div>`;
        }
      },
      {
        name: "Produk",
        styleHead: "w-24 text-left whitespace-no-wrap align-top",
        render: (item: BookingCommissionData) => {
          return `<div class="overflow-ellipsis text-left">${item.bcDetailProductName}</div>`;
        }
      },
      {
        name: "Komoditas",
        styleHead: "w-40 text-left whitespace-no-wrap align-top",
        render: (item: BookingCommissionData) => {
          return `<div class="overflow-ellipsis text-left">${item.bcDetailCommodityName}</div>`;
        }
      },
      {
        name: "Tanggal Dibuat",
        styleHead: "w-40 text-left whitespace-no-wrap align-top",
        render: (item: BookingCommissionData) => {
          return `<div class="flex flex-col"><div class="overflow-ellipsis text-left">${formatDateWithoutTime(
            item.bcCreatedAt
          )}</div>
          <div class="truncate text-gray-lp-600 w-32">${
            item.bcCreatedName
          }</div></div>`;
        }
      }
    ];
  }

  get advancedFilterData() {
    return BookingCommissionController.advanceFilterData;
  }

  get isAdvancedFilterData() {
    return (
      !!this.advancedFilterData.bookingSource.value ||
      !!this.advancedFilterData.appliedTo.value ||
      !!this.advancedFilterData.shipmentPrefix ||
      !!this.advancedFilterData.cityOriginCode ||
      !!this.advancedFilterData.cityDestinationCode ||
      !!this.advancedFilterData.shipmentProduct ||
      !!this.advancedFilterData.commodity
    );
  }

  posAppliedTo = [
    { name: "Booking Shipment", value: "shipment" },
    { name: "Booking Client", value: "for_client" },
    { name: "Booking Conventional", value: "manual" }
  ];
  clientAppliedTo = [{ name: "Booking Client", value: "manual" }];
  defaultApliedTo = [
    { name: "Booking Shipment", value: "shipment" },
    { name: "Booking Client", value: "for_client,manual" },
    { name: "Booking Conventional", value: "manual" }
  ];
  get appliedToListData() {
    const source = this.advancedFilterData.bookingSource.value;
    if (source === "pos") {
      return this.posAppliedTo;
    } else if (source === "client") {
      return this.clientAppliedTo;
    } else {
      return this.defaultApliedTo;
    }
  }

  isAdvancedFilterOpened = false;
  onChangeAdvancedFilter = debounce(event => {
    const source = this.advancedFilterData.bookingSource.value;
    const appliedTo = this.advancedFilterData.appliedTo.name;

    if (appliedTo) {
      if (source === "client") {
        const isExist = this.clientAppliedTo.find(e => e.name === appliedTo);
        if (isExist) {
          event["appliedTo"] = isExist;
        }
      } else if (source === "pos") {
        const isExist = this.posAppliedTo.find(e => e.name === appliedTo);
        if (isExist) {
          event["appliedTo"] = isExist;
        }
      }
    }
    this.setFirstPage();
    BookingCommissionController.setAdvanceFilterData(event);
    this.fetchBookingCommissionList();
  }, 500);

  setFirstPage() {
    BookingCommissionController.setFirstPage();
  }

  onOpenedAdvancedFilter(event: any) {
    this.isAdvancedFilterOpened = event;
  }

  onResetAdvancedFilter = debounce(() => {
    BookingCommissionController.setAdvanceFilterData({
      bookingSource: { name: "", value: "" },
      appliedTo: { name: "", value: "" },
      shipmentPrefix: "",
      cityOriginCode: "",
      cityDestinationCode: "",
      shipmentProduct: "",
      commodity: ""
    });
    this.fetchBookingCommissionList();
  }, 500);

  onClickRow(item: BookingCommissionData) {
    router.push(`/tarif/commission-configuration/booking/${item.bcId}`);
  }

  commissionBookingSource(val: string) {
    if (val === "") {
      return "-";
    }
    return COMMISSION_BOOKING_SOURCE.find((item: any) => {
      return item.value == val;
    })?.name;
  }

  commissionFor(bookingSource: string, val: string) {
    if (bookingSource === "" || val === "") {
      return "-";
    }
    const options =
      bookingSource === "pos"
        ? COMMISSION_FOR_OPTIONS
        : COMMISSION_FOR_CLIENT_OPTIONS;
    return options.find((item: any) => {
      return item.value == val;
    })?.name;
  }
}
