import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "inline-flex" }
const _hoisted_2 = { class: "w-10/12 space-y-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lp_button = _resolveComponent("lp-button")!
  const _component_booking_commission_form_criteria_booking = _resolveComponent("booking-commission-form-criteria-booking")!
  const _component_booking_commission_form_commission_rules = _resolveComponent("booking-commission-form-commission-rules")!
  const _component_booking_commission_form_tier_calculation = _resolveComponent("booking-commission-form-tier-calculation")!
  const _component_DetailLayout = _resolveComponent("DetailLayout")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_DetailLayout, {
      onBack: _ctx.goBack,
      title: "Buat Komisi Booking",
      "full-page": "",
      "custom-class": "px-0",
      "vertical-separator": false,
      "leave-page-confirmation": _ctx.leavePageConfirmation,
      "onUpdate:leave-page-confirmation": _cache[2] || (_cache[2] = ($event: any) => (_ctx.leavePageConfirmation = $event)),
      onLeavePage: _ctx.onLeavePage
    }, {
      "top-right": _withCtx(() => [
        _createVNode("div", _hoisted_1, [
          _createVNode(_component_lp_button, {
            "text-color": "white",
            customClass: "w-full py-2.5",
            title: "Simpan",
            onClick: _ctx.onSaveForm,
            disabled: !_ctx.isFormValid
          }, null, 8, ["onClick", "disabled"])
        ])
      ]),
      data: _withCtx(() => [
        _createVNode("div", _hoisted_2, [
          _createVNode(_component_booking_commission_form_criteria_booking),
          _createVNode(_component_booking_commission_form_commission_rules),
          _createVNode(_component_booking_commission_form_tier_calculation, {
            isValid: _ctx.validCalculationTier,
            "onUpdate:isValid": _cache[1] || (_cache[1] = ($event: any) => (_ctx.validCalculationTier = $event))
          }, null, 8, ["isValid"])
        ])
      ]),
      _: 1
    }, 8, ["onBack", "leave-page-confirmation", "onLeavePage"]),
    (_ctx.saveConfirmation)
      ? (_openBlock(), _createBlock(_component_Modal, {
          key: 0,
          onSubmit: _ctx.saveBookingCommission,
          onClose: _ctx.closeSaveConfirmation,
          title: "Konfirmasi Pembuatan",
          message: 
      `Anda yakin ingin menyimpan pembuatan Booking Komisi untuk ${_ctx.commissionForName} ?`
    ,
          image: "are-you-sure",
          textSuccess: "Simpan",
          textCancel: "Kembali",
          class: "px-8"
        }, null, 8, ["onSubmit", "onClose", "message"]))
      : _createCommentVNode("", true),
    (_ctx.successSave)
      ? (_openBlock(), _createBlock(_component_Modal, {
          key: 1,
          onSubmit: _ctx.closeSuccessSave,
          title: "Pembuatan Berhasil !",
          message: "Booking komisi berhasil dibuat.",
          image: "image-modal-success",
          textSuccess: "OK",
          class: "px-8"
        }, null, 8, ["onSubmit"]))
      : _createCommentVNode("", true)
  ], 64))
}