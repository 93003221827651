/* eslint-disable @typescript-eslint/camelcase */
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { container } from "tsyringe";
import {
  CommissionsDtpol,
  DeliveryTieringPolicyData,
  DeliveryTieringPolicyEntities
} from "@/domain/entities/DeliveryTieringPolicy";
import { DeliveryTieringPolicyPresenter } from "../presenters/DeliveryTieringPolicyPresenter";
import { Pagination } from "@/domain/entities/Pagination";
import { MainAppController } from "./MainAppController";
import {
  formatDecimal,
  parsingErrorResponse
} from "@/app/infrastructures/misc/Utils";
import { EditDeliveryTieringPolicy } from "@/data/payload/api/DeliveryTieringPolicyApiRequest";

export interface DeliveryTieringPolicyState {
  isLoading: boolean;
  isError: boolean;
  errorCause: string;
}

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "delivery_tiering_policy"
})
class DeliveryTieringPolicyStore extends VuexModule
  implements DeliveryTieringPolicyState {
  public isLoading = false;
  public isError = false;
  public errorCause = "";
  public isSuccessEdit = false;
  public search = "";
  public listData = new DeliveryTieringPolicyEntities(
    new Pagination(1, 10),
    []
  );
  public detailData = new DeliveryTieringPolicyData(
    0,
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    []
  );
  public editDetailData = new DeliveryTieringPolicyData(
    0,
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    []
  );

  //edit
  form = {
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor.",
    commissionCalculation: [
      {
        publishRateCommission: "0",
        shippingSurchargeCommission: "0",
        codCommission: "0"
      },
      {
        publishRateCommission: "0",
        shippingSurchargeCommission: "0",
        codCommission: "0"
      },
      {
        publishRateCommission: "0",
        shippingSurchargeCommission: "0",
        codCommission: "0"
      },
      {
        publishRateCommission: "0",
        shippingSurchargeCommission: "0",
        codCommission: "0"
      },
      {
        publishRateCommission: "0",
        shippingSurchargeCommission: "0",
        codCommission: "0"
      }
    ]
  };

  @Action
  editDtpol(params: EditDeliveryTieringPolicy) {
    this.setLoading(true);
    const presenter = container.resolve(DeliveryTieringPolicyPresenter);
    presenter
      .editDeliveryTieringPolicyDetail(params)
      .then(() => {
        this.setSuccessEdit(true);
      })
      .catch(err => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Perubahan Gagal !", () => {
            MainAppController.closeErrorMessage();
            this.editDtpol(params);
          })
        );
      })
      .finally(() => this.setLoading(false));
  }

  @Action
  getList(params: { page: number; limit: number; search: string }) {
    this.setLoading(true);
    const presenter = container.resolve(DeliveryTieringPolicyPresenter);
    return presenter
      .getDeliveryTieringPolicyList(params.page, params.limit, params.search)
      .then((res: DeliveryTieringPolicyEntities) => {
        this.setListData(res);
        this.setErrorCause("");
      })
      .catch((err: any) => {
        this.setErrorCause(err.response ? "server" : "internet");
      })
      .finally(() => this.setLoading(false));
  }

  @Action
  getDetail(id: number) {
    this.setLoading(true);
    const presenter = container.resolve(DeliveryTieringPolicyPresenter);
    return presenter
      .getDeliveryTieringPolicyDetail(id)
      .then((res: DeliveryTieringPolicyData) => {
        this.setDetailData(res);
        this.setEditDetailData(res);
        this.setErrorCause("");
      })
      .catch((err: any) => {
        this.setErrorCause(err.response ? "server" : "internet");
      })
      .finally(() => this.setLoading(false));
  }

  @Action
  public fetchList() {
    this.getList({
      page: this.listData.pagination.page,
      limit: this.listData.pagination.limit,
      search: this.search
    });
  }

  @Mutation
  public setSuccessEdit(val: boolean) {
    this.isSuccessEdit = val;
  }

  @Mutation
  public setListData(data: DeliveryTieringPolicyEntities) {
    this.listData = data;
  }

  @Mutation
  public setSearch(val: string) {
    this.search = val;
  }

  @Mutation
  public setErrorCause(val: string) {
    this.errorCause = val;
  }

  @Mutation
  public setDetailData(data: DeliveryTieringPolicyData) {
    this.detailData = data;
  }

  @Mutation
  public setLoading(val: boolean) {
    this.isLoading = val;
  }

  @Mutation
  public setEditDetailData(val: DeliveryTieringPolicyData) {
    this.editDetailData = {
      ...val,
      commissions: val.commissions.map(
        item =>
          new CommissionsDtpol(
            item.id,
            item.productId,
            item.tier,
            formatDecimal(`${item.publishRateCommission}`),
            formatDecimal(`${item.shippingSurchargeCommission}`),
            formatDecimal(`${item.codCommission}`),
            item.createdAt,
            item.updatedAt
          )
      )
    };
  }
}

export const DeliveryTieringPolicyController = getModule(
  DeliveryTieringPolicyStore
);
