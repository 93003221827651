
import { Vue, Options } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import { BookingCommissionController } from "@/app/ui/controllers/BookingCommissionControllers";
import {
  parseInputFloatToFloat,
  formatInputMoneyWithDecimal
} from "@/app/infrastructures/misc/Utils";
import { BookingCommissionCalculationTier } from "@/domain/entities/BookingCommission";

@Options({
  components: {
    Title
  },
  emits: ["update:isValid"],
  props: {
    formType: {
      default: "add",
      type: String
    },
    isValid: {
      required: true
    }
  }
})
export default class BookingCommissionFormTierCalculation extends Vue {
  props: any = this.$props;
  get form() {
    return BookingCommissionController.bookingCommissionForm;
  }

  get startEndAmountType() {
    if (this.form.commissionTierBase === "total_base_rate") {
      return {
        prefix: "IDR"
      };
    }
    if (this.form.commissionTierBase === "chargeable_weight") {
      return {
        suffix: "Kg"
      };
    }
    return {};
  }
  get commissionAmountType() {
    if (this.form.commissionType === "percentage") {
      return {
        suffix: "%"
      };
    }
    if (
      this.form.commissionType === "flat" ||
      this.form.commissionType === "per_kg"
    ) {
      return {
        prefix: "IDR"
      };
    }
    return {};
  }

  formatNumber(value: string) {
    return formatInputMoneyWithDecimal(value, 2);
  }

  formatCommissionAmount(value: string) {
    return formatInputMoneyWithDecimal(value);
  }

  parseToNumber(str: string) {
    return parseInputFloatToFloat(str);
  }

  isErrorCommissionAmount(index: number) {
    let result: boolean;
    if (this.form.commissionType === "percentage") {
      result =
        this.parseToNumber(
          this.form.commissionCalculationTier[index].commissionAmount
        ) < 0 ||
        this.parseToNumber(
          this.form.commissionCalculationTier[index].commissionAmount
        ) > 100;
      return result;
    }
    result =
      this.parseToNumber(
        this.form.commissionCalculationTier[index].commissionAmount
      ) < 0 ||
      this.parseToNumber(
        this.form.commissionCalculationTier[index].commissionAmount
      ) > 1000000;
    return result;
  }
  isErrorStartEndAmount(index: number) {
    const startAmount = this.parseToNumber(
      this.form.commissionCalculationTier[index].startAmount
    );
    const endAmount = this.parseToNumber(
      this.form.commissionCalculationTier[index].endAmount
    );
    if (!startAmount || !endAmount) {
      return false;
    }
    return startAmount >= endAmount;
  }
  isIntersect(val: any, index: number) {
    const result = false;
    for (let i = 0; i < this.form.commissionCalculationTier.length; i++) {
      if (index !== i) {
        const tempStart = this.parseToNumber(
          this.form.commissionCalculationTier[i].startAmount
        );
        const tempEnd = this.parseToNumber(
          this.form.commissionCalculationTier[i].endAmount
        );
        if (tempStart <= val && val <= tempEnd) {
          return true;
        }
      }
    }
    return result;
  }
  isErrorStartIntersection(index: number) {
    const startAmount = this.parseToNumber(
      this.form.commissionCalculationTier[index].startAmount
    );
    if (!startAmount) {
      return false;
    }
    if (index > 0) {
      return (
        startAmount <=
        this.parseToNumber(
          this.form.commissionCalculationTier[index - 1].endAmount
        )
      );
    }
    return this.isIntersect(startAmount, index);
  }
  isErrorEndIntersection(index: number) {
    const endAmount = this.parseToNumber(
      this.form.commissionCalculationTier[index].endAmount
    );
    if (!endAmount) {
      return false;
    }
    return this.isIntersect(endAmount, index);
  }
  get isCalculationTierValid() {
    let result = true;
    for (let i = 0; i < this.form.commissionCalculationTier.length; i++) {
      const tier = this.form.commissionCalculationTier[i];
      if (this.validateStartDateEndDate(tier) || this.validationTierAmount(tier)) {
        result = false;
        break;
      }
      if (this.isErrorStartIntersection(i) || this.validateErrorStarDateEndDate(i)) {
        result = false;
        break;
      }

      if (this.stringToNumber(tier.startAmount) > this.maxStartEndValue) {
        result = false;
        break;
      }

      if (this.stringToNumber(tier.endAmount) > this.maxStartEndValue) {
        result = false;
        break;
      }

      if (this.stringToNumber(tier.commissionAmount) > this.maxCommissionValue) {
        result = false;
        break;
      }

      if (this.stringToNumber(tier.commissionAmountBranch) > this.maxCommissionValue) {
        result = false;
        break;
      }

      if (this.stringToNumber(tier.commissionAmountPickup) > this.maxCommissionValue) {
        result = false;
        break;
      }

      if (this.stringToNumber(tier.commissionAmountPickupPublishRate) > this.maxCommissionValue) {
        result = false;
        break;
      }
    }
    this.$emit("update:isValid", result);
    return "";
  }

  validationTierAmount(tier: BookingCommissionCalculationTier) {
    return !tier.commissionAmount || !tier.commissionAmountBranch || !tier.commissionAmountPickup || !tier.commissionAmountPickupPublishRate;
  }

  validateStartDateEndDate(tier: any) {
    return !tier.startAmount || !tier.endAmount;
  }

  validateErrorStarDateEndDate(index: number) {
    return  this.isErrorStartEndAmount(index) || this.isErrorEndIntersection(index);
  }

  get maxStartEndValue() {
    return 1000000000;
  }

  get maxCommissionValue() {
    return this.form.commissionType === 'percentage' ? 100 : 1000000;
  }

  stringToNumber(value: string) {
    return Number(value.replaceAll(".", ""));
  }

  isErrorCommissionAmountBranch(index: number) {
    let result: boolean;
    if (this.form.commissionType === "percentage") {
      result =
        this.parseToNumber(
          this.form.commissionCalculationTier[index].commissionAmountBranch
        ) < 0 ||
        this.parseToNumber(
          this.form.commissionCalculationTier[index].commissionAmountBranch
        ) > 100;
      return result;
    }
    result =
      this.parseToNumber(
        this.form.commissionCalculationTier[index].commissionAmountBranch
      ) < 0 ||
      this.parseToNumber(
        this.form.commissionCalculationTier[index].commissionAmountBranch
      ) > 1000000;
    return result;
  }

  isErrorCommissionAmountPickup(index: number) {
    let result: boolean;
    if (this.form.commissionType === "percentage") {
      result =
        this.parseToNumber(
          this.form.commissionCalculationTier[index].commissionAmountPickup
        ) < 0 ||
        this.parseToNumber(
          this.form.commissionCalculationTier[index].commissionAmountPickup
        ) > 100;
      return result;
    }
    result =
      this.parseToNumber(
        this.form.commissionCalculationTier[index].commissionAmountPickup
      ) < 0 ||
      this.parseToNumber(
        this.form.commissionCalculationTier[index].commissionAmountPickup
      ) > 1000000;
    return result;
  }

  isErrorCommissionAmountPickupPublishRate(index: number) {
    let result: boolean;
    if (this.form.commissionType === "percentage") {
      result =
        this.parseToNumber(
          this.form.commissionCalculationTier[index].commissionAmountPickupPublishRate
        ) < 0 ||
        this.parseToNumber(
          this.form.commissionCalculationTier[index].commissionAmountPickupPublishRate
        ) > 100;
      return result;
    }
    result =
      this.parseToNumber(
        this.form.commissionCalculationTier[index].commissionAmountPickupPublishRate
      ) < 0 ||
      this.parseToNumber(
        this.form.commissionCalculationTier[index].commissionAmountPickupPublishRate
      ) > 1000000;
    return result;
  }
}
