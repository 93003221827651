
import { Vue, Options } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import { BookingCommissionController } from "@/app/ui/controllers/BookingCommissionControllers";

@Options({
  components: {
    Title,
  },
})
export default class BookingCommissionDetailTierCalculation extends Vue {
  get detailData() {
    return BookingCommissionController.bookingCommissionDetail;
  }

  columns = [
    {
      name: "Tier",
      styleHead: "w-1/20 text-left whitespace-no-wrap",
      render: (item: any, index: number) => {
        return `<span class='flex text-black-lp-300'>${index + 1}</span>`;
      },
    },
    {
      name: "Mulai Dari",
      styleHead: "w-2/20 text-left whitespace-no-wrap",
      render: (item: any) => {
        const values =
          this.detailData.commissionTierBase === "total_base_rate"
            ? `IDR ${item.startAmount}`
            : `${item.startAmount} Kg`;

        return `<div class='flex text-black-lp-300'>${values}</div>`;
      },
    },
    {
      name: "Sampai",
      styleHead: "w-2/20 text-left whitespace-no-wrap",
      render: (item: any) => {
        const values =
          this.detailData.commissionTierBase === "total_base_rate"
            ? `IDR ${item.endAmount}`
            : `${item.endAmount} Kg`;

        return `<div class='flex text-black-lp-300'>${values}</div>`;
      },
    },
    {
      name: "Komisi Induk",
      styleHead: "w-2/20 text-left whitespace-no-wrap",
      render: (item: any) => {
        const values =
          this.detailData.commissionType === "percentage"
            ? `${item.commissionAmount} %`
            : `IDR ${item.commissionAmount}`;

        return `<div class='flex text-black-lp-300'>${values}</div>`;
      },
    },
    {
      name: "Komisi Cabang",
      styleHead: "w-2/20 text-left whitespace-no-wrap",
      render: (item: any) => {
        const values =
          this.detailData.commissionType === "percentage"
            ? `${item.commissionAmountBranch} %`
            : `IDR ${item.commissionAmountBranch}`;

        return `<div class='flex text-black-lp-300'>${values}</div>`;
      },
    },
    {
      name: "Komisi di Pick Up",
      styleHead: "w-2/20 text-left whitespace-no-wrap",
      render: (item: any) => {
        const values =
          this.detailData.commissionType === "percentage"
            ? `${item.commissionAmountPickup} %`
            : `IDR ${item.commissionAmountPickup}`;

        return `<div class='flex text-black-lp-300'>${values}</div>`;
      },
    },
    {
      name: "Komisi Pick Up",
      styleHead: "w-2/20 text-left whitespace-no-wrap",
      render: (item: any) => {
        const values =
          this.detailData.commissionType === "percentage"
            ? `${item.commissionAmountPickupPublishRate} %`
            : `IDR ${item.commissionAmountPickupPublishRate}`;

        return `<div class='flex text-black-lp-300'>${values}</div>`;
      },
    },
  ];
}
