export const TIER_BASE_OPTIONS = [
  {
    name: "Pilih tier base",
    value: ""
  },
  {
    name: "Total Base Rate",
    value: "total_base_rate"
  },
  {
    name: "Chargeable Weight",
    value: "chargeable_weight"
  }
];

export const PRICE_TIER_REFERENCE_OPTIONS = [
  {
    name: "Pilih tier acuan",
    value: ""
  },
  {
    name: "Retail",
    value: "retail"
  },
  {
    name: "Client",
    value: "client"
  }
];

export const PRICE_REFERENCE_OPTIONS = [
  {
    name: "Pilih harga acuan",
    value: ""
  },
  {
    name: "Retail",
    value: "retail"
  },
  {
    name: "Client",
    value: "client"
  }
];

export const COMMISSION_TYPE_OPTIONS = [
  {
    name: "Pilih komisi",
    value: ""
  },
  {
    name: "Persentase",
    value: "percentage"
  },
  {
    name: "Per KG",
    value: "per_kg"
  },
  {
    name: "Flat",
    value: "flat"
  }
];

export const COMMISSION_QUANTIFIER_OPTIONS = [
  {
    name: "Pilih perhitungan komisi",
    value: ""
  },
  {
    name: "Publish Rate",
    value: "publish_rate"
  },
  {
    name: "Publish Rate + Shipping Surcharge",
    value: "publish_rate,shipping_surcharge"
  },
  {
    name: "Publish Rate + Shipping Surcharge + Destination Forward Rate",
    value: "publish_rate,shipping_surcharge,destination_forward_rate"
  },
  {
    name:
      "Publish Rate + Shipping Surcharge + Origin Forward Rate + Destination Forward Rate",
    value:
      "publish_rate,shipping_surcharge,origin_forward_rate,destination_forward_rate"
  }
];

export const COMMISSION_FOR_OPTIONS = [
  {
    name: "Pilih",
    value: ""
  },
  {
    name: "Booking Shipment",
    value: "shipment"
  },
  {
    name: "Booking Client",
    value: "for_client"
  },
  {
    name: "Booking Conventional",
    value: "manual"
  }
];

export const COMMISSION_FOR_CLIENT_OPTIONS = [
  {
    name: "Pilih",
    value: ""
  },
  {
    name: "Booking Client",
    value: "manual"
  }
];

export const COMMISSION_BOOKING_SOURCE = [
  {
    name: "Pilih sumber",
    value: ""
  },
  {
    name: "POS",
    value: "pos"
  },
  {
    name: "Client",
    value: "client"
  }
];
