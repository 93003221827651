
import { Vue, Options } from "vue-class-component";
import CreateUpdate from "@/app/ui/components/data-wrapper/create-update.vue";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import { BulkBookingCommissionController } from "@/app/ui/controllers/BulkBookingCommissionController";
import BookingCommissionDetailCriteriaBooking from "@/app/ui/views/commission-configuration/bulk-booking-commission/detail/detail-criteria-booking.vue";
import BookingCommissionDetailCommissionRules from "@/app/ui/views/commission-configuration/bulk-booking-commission/detail/detail-commission-rules.vue";
import BookingCommissionDetailTierCalculation from "@/app/ui/views/commission-configuration/bulk-booking-commission/detail/detail-tier-calculation.vue";
import BookingCommissionDetailCriteriaBookingFile from "@/app/ui/views/commission-configuration/bulk-booking-commission/detail/detail-booking-rules-downloaded-files.vue";

@Options({
  components: {
    BookingCommissionDetailTierCalculation,
    BookingCommissionDetailCommissionRules,
    BookingCommissionDetailCriteriaBooking,
    BookingCommissionDetailCriteriaBookingFile,
    CreateUpdate,
    DetailLayout
  }
})
export default class BookingCommissionDetail extends Vue {
  mounted() {
    this.getDetail();
  }

  get isLoading() {
    return BulkBookingCommissionController.isLoading;
  }
  get errorCause() {
    return BulkBookingCommissionController.errorCause;
  }
  get isError() {
    return BulkBookingCommissionController.isError;
  }

  getDetail() {
    BulkBookingCommissionController.fetchBulkBookingCommissionDetail({
      id: this.$route.params.id
    });
  }
  get detailData() {
    return BulkBookingCommissionController.bulkBookingCommissionDetail;
  }
  get title() {
    return `ID: ${this.detailData.commissionId}`;
  }

  goBack() {
    this.$router.push("/tarif/commission-configuration/bulk-booking");
  }
}
