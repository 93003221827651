
import { Vue, Options } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import { BulkBookingCommissionController } from "@/app/ui/controllers/BulkBookingCommissionController";
import { downloadFile } from "@/app/infrastructures/misc/Utils";

@Options({
  components: {
    Title
  }
})
export default class DetailBookingRulesDownloadedFiles extends Vue {
  get detailData() {
    return BulkBookingCommissionController.bulkBookingCommissionDetail;
  }

  // download file
  download(url: string, fileName: string) {
    const newFileName = fileName
      .split(".")
      .slice(0, -1)
      .join(".");
    downloadFile(url, newFileName, ".csv");
  }

  get successFile() {
    const findIndex = this.detailData.successFileName.indexOf("files");
    return this.detailData.successFileName.charAt(findIndex + 7) === "/"
      ? this.detailData.successFileName.slice(
          findIndex + 49,
          this.detailData.successFileName.length
        )
      : "";
  }

  get failedFile() {
    const findIndex = this.detailData.failedFileName.indexOf("files");
    return this.detailData.failedFileName.charAt(findIndex + 7) === "/"
      ? this.detailData.failedFileName.slice(
          findIndex + 49,
          this.detailData.failedFileName.length
        )
      : "";
  }
}
