
import { Vue, Options } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import { BulkBookingCommissionController } from "@/app/ui/controllers/BulkBookingCommissionController";
import { formatPriceIDR } from "@/app/infrastructures/misc/Utils";

@Options({
  components: {
    Title
  }
})
export default class DetailTierCalculation extends Vue {
  get detailData() {
    return BulkBookingCommissionController.bulkBookingCommissionDetail;
  }

  columns = [
    {
      name: "Tier",
      styleHead: "w-1/20 text-left whitespace-no-wrap",
      render: (item: any, index: any) => {
        return `<span class='flex text-black-lp-300'>${index + 1}</span>`;
      }
    },
    {
      name: "Mulai Dari",
      styleHead: "w-1/20 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class='flex text-black-lp-300'>${
          this.detailData.commissionTierBase === "total_base_rate"
            ? formatPriceIDR(item.startWeight)
            : item.startWeight + " Kg"
        }</div>`;
      }
    },
    {
      name: "Sampai",
      styleHead: "w-1/20 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class='flex text-black-lp-300'>${
          this.detailData.commissionTierBase === "total_base_rate"
            ? formatPriceIDR(item.endWeight)
            : item.endWeight + " Kg"
        }</div>`;
      }
    },
    {
      name: "Komisi Induk",
      styleHead: "w-1/20 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class='flex text-black-lp-300'>${
          this.detailData.commissionType === "percentage"
            ? item.commissionAmount + " %"
            : formatPriceIDR(item.commissionAmount)
        }</div>`;
      }
    },
     {
      name: "Komisi Cabang",
      styleHead: "w-2/20 text-left whitespace-no-wrap",
      render: (item: any) => {
        const values =
          this.detailData.commissionType === "percentage"
            ? `${item.commissionAmountBranch} %`
            : `IDR ${item.commissionAmountBranch}`;

        return `<div class='flex text-black-lp-300'>${values}</div>`;
      },
    },
    {
      name: "Komisi di Pick Up",
      styleHead: "w-2/20 text-left whitespace-no-wrap",
      render: (item: any) => {
        const values =
          this.detailData.commissionType === "percentage"
            ? `${item.commissionAmountPickup} %`
            : `IDR ${item.commissionAmountPickup}`;

        return `<div class='flex text-black-lp-300'>${values}</div>`;
      },
    },
    {
      name: "Komisi Pick Up",
      styleHead: "w-2/20 text-left whitespace-no-wrap",
      render: (item: any) => {
        const values =
          this.detailData.commissionType === "percentage"
            ? `${item.commissionAmountPickupPublishRate} %`
            : `IDR ${item.commissionAmountPickupPublishRate}`;

        return `<div class='flex text-black-lp-300'>${values}</div>`;
      },
    },
  ];
}
