/* eslint-disable @typescript-eslint/camelcase */
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import {
  BulkBookingCommissionDetail,
  BulkBookingCommissionEntities
} from "@/domain/entities/BulkBookingCommission";
import { container } from "tsyringe";
import { BulkBookingCommissionPresenter } from "../presenters/BulkBookingCommissionPresenter";
import { BulkBookingCommissionCreate } from "@/data/payload/api/BulkBookingCommissionApiRequest";
import { Pagination } from "@/domain/entities/Pagination";
import { MainAppController } from "./MainAppController";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";

export interface BulkBookingCommissionState {
  isLoading: boolean;
  isError: boolean;
  errorCause: string;
  bulkBookingCommissionList: any;
  bulkBookingCommissionDetail: BulkBookingCommissionDetail;
}

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "bulk_booking_commission"
})
class BulkBookingCommissionStore extends VuexModule
  implements BulkBookingCommissionState {
  public isLoading = false;
  public isError = false;
  public errorCause = "";
  public bulkBookingCommissionList = new BulkBookingCommissionEntities(
    new Pagination(1, 10),
    []
  );
  public bulkBookingCommissionDetail = new BulkBookingCommissionDetail();
  public search = "";
  public status = "";
  public form = {
    documentTypeName: "Booking Commission",
    documentTypeValue: "booking-commission",
    activityName: "",
    description: "",
    bookingSource: { name: "", value: "" },
    appliedTo: { name: "", value: "" },
    shipmentId: [] as Array<{ name: string; value: string }>,
    tierBase: { name: "", value: "" },
    tierReferencePrice: { name: "", value: "" },
    commissionReferencePrice: { name: "", value: "" },
    commissionType: { name: "", value: "" },
    quantifier: { name: "", value: "" },
    tierCalculation: [
      { from: "0", to: "0", commission: "0", commissionBranch: "0", commissionDipickup: "0", commissionPickup: "0" },
      { from: "0", to: "0", commission: "0", commissionBranch: "0", commissionDipickup: "0", commissionPickup: "0" },
      { from: "0", to: "0", commission: "0", commissionBranch: "0", commissionDipickup: "0", commissionPickup: "0" },
      { from: "0", to: "0", commission: "0", commissionBranch: "0", commissionDipickup: "0", commissionPickup: "0" },
      { from: "0", to: "0", commission: "0", commissionBranch: "0", commissionDipickup: "0", commissionPickup: "0" }
    ],
    file: "" as any
  };
  public openSuccess = false;
  public isFailed = false;
  public failedMessage =
    "Konfigurasi komisi booking sudah pernah dibuat,<br />silakan ke halaman detail edit untuk mengubah<br />konfigurasi.";

  @Action
  public createBulkBookingCommission(params: BulkBookingCommissionCreate) {
    MainAppController.showLoading();
    const presenter = container.resolve(BulkBookingCommissionPresenter);
    return presenter
      .createBulkBookingCommission(params)
      .then(res => {
        return res;
      })
      .catch(err => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Pengunggahan Gagal !", () => {
            MainAppController.closeErrorMessage();
            this.createBulkBookingCommission(params);
          })
        );
      })
      .finally(() => MainAppController.closeLoading());
  }

  @Action
  public fetchBulkBookingCommissionList(params: {
    page: number;
    limit: number;
    search: string;
    status: string;
  }) {
    this.setLoading(true);
    const presenter = container.resolve(BulkBookingCommissionPresenter);
    presenter
      .getBulkBookingCommissionList(
        params.page,
        params.limit,
        params.search,
        params.status
      )
      .then((res: BulkBookingCommissionEntities) => {
        this.setBulkBookingCommissionList(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public initBulkBookingCommissionList() {
    this.setBulkBookingCommissionList(
      new BulkBookingCommissionEntities(new Pagination(1, 10), [])
    );
    this.setSearch("");
    this.setStatus("");
  }

  @Action
  public getBulkBookingCommissionList() {
    this.fetchBulkBookingCommissionList({
      page: this.bulkBookingCommissionList.pagination.page,
      limit: this.bulkBookingCommissionList.pagination.limit,
      search: this.search,
      status: this.status
    });
  }

  @Action
  public fetchBulkBookingCommissionDetail(params: { id: any }) {
    this.setLoading(true);
    const presenter = container.resolve(BulkBookingCommissionPresenter);
    presenter
      .getBulkBookingCommissionDetail(Number(params.id))
      .then((res: BulkBookingCommissionDetail) => {
        this.setBulkBookingCommissionDetail(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public async searchAction(value: string) {
    await this.setSearch(value);
    await this.setFirstPage();
    await this.getBulkBookingCommissionList();
  }

  @Action
  public setFirstPage() {
    this.setFirst();
  }

  @Mutation
  private async setFirst() {
    this.bulkBookingCommissionList.pagination.page = 1;
  }

  @Mutation
  public setSearch(val: string) {
    this.search = val;
  }

  @Mutation
  public setStatus(val: string) {
    this.status = val;
  }

  @Mutation
  public setBulkBookingCommissionList(data: any) {
    this.bulkBookingCommissionList = data;
  }

  @Mutation
  public setLoading(val: boolean) {
    this.isLoading = val;
  }

  @Mutation
  public setError(val: boolean) {
    this.isError = val;
  }

  @Mutation
  public setErrorCause(val: string) {
    this.errorCause = val;
  }

  @Mutation
  public setOpenSuccess(val: boolean) {
    this.openSuccess = val;
  }

  @Mutation
  public setFailed(val: boolean) {
    this.isFailed = val;
  }

  @Mutation
  public setFailedMessage(val: string) {
    this.failedMessage = val;
  }

  @Mutation
  public resetForm() {
    this.form = {
      documentTypeName: "Booking Commission",
      documentTypeValue: "booking-commission",
      activityName: "",
      description: "",
      bookingSource: { name: "", value: "" },
      appliedTo: { name: "", value: "" },
      shipmentId: [] as Array<{ name: string; value: string }>,
      tierBase: { name: "", value: "" },
      tierReferencePrice: { name: "", value: "" },
      commissionReferencePrice: { name: "", value: "" },
      commissionType: { name: "", value: "" },
      quantifier: { name: "", value: "" },
      tierCalculation: [
        { from: "0", to: "0", commission: "0", commissionBranch: "0", commissionDipickup: "0", commissionPickup: "0" },
        { from: "0", to: "0", commission: "0", commissionBranch: "0", commissionDipickup: "0", commissionPickup: "0" },
        { from: "0", to: "0", commission: "0", commissionBranch: "0", commissionDipickup: "0", commissionPickup: "0" },
        { from: "0", to: "0", commission: "0", commissionBranch: "0", commissionDipickup: "0", commissionPickup: "0" },
        { from: "0", to: "0", commission: "0", commissionBranch: "0", commissionDipickup: "0", commissionPickup: "0" }
      ],
      file: "" as any
    };
  }

  @Mutation
  public setBulkBookingCommissionDetail(data: BulkBookingCommissionDetail) {
    this.bulkBookingCommissionDetail = data;
  }
}

export const BulkBookingCommissionController = getModule(
  BulkBookingCommissionStore
);
