
import { Vue, Options } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import BookingCommissionFormCriteriaBooking from "@/app/ui/views/commission-configuration/booking-commission/booking-commission-form/booking-commission-form-criteria-booking.vue";
import BookingCommissionFormCommissionRules from "@/app/ui/views/commission-configuration/booking-commission/booking-commission-form/booking-commission-form-commission-rules.vue";
import BookingCommissionFormTierCalculation from "@/app/ui/views/commission-configuration/booking-commission/booking-commission-form/booking-commission-form-tier-calculation.vue";
import { BookingCommissionController } from "@/app/ui/controllers/BookingCommissionControllers";
import { BookingCommissionDetail } from "@/domain/entities/BookingCommission";
import { COMMISSION_FOR_OPTIONS } from "@/app/ui/views/commission-configuration/booking-commission/BookingCommissionUtils";

@Options({
  components: {
    DetailLayout,
    BookingCommissionFormCriteriaBooking,
    BookingCommissionFormCommissionRules,
    BookingCommissionFormTierCalculation
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (this.answerLeavingPage) {
      next();
    } else {
      this.leavePageConfirmation = true;
      this.nextPath = to.path;
      next(false);
    }
  }
})
export default class BookingCommissionAdd extends Vue {
  mounted() {
    BookingCommissionController.setBookingCommissionForm(
      new BookingCommissionDetail()
    );
  }
  leavePageConfirmation = false;
  saveConfirmation = false;
  get successSave() {
    return BookingCommissionController.successSave;
  }
  set successSave(bool: boolean) {
    BookingCommissionController.setSuccessSave(bool);
  }
  nextPath = "";
  answerLeavingPage = false;
  goBack() {
    this.$router.push("/tarif/commission-configuration/booking");
  }
  onLeavePage() {
    this.answerLeavingPage = true;
    this.$router.push(this.nextPath);
  }
  closeSaveConfirmation() {
    this.saveConfirmation = false;
  }
  closeSuccessSave() {
    this.successSave = false;
    this.answerLeavingPage = true;
    this.goBack();
  }

  onSaveForm() {
    if (!this.isFormValid) {
      return;
    }
    this.saveConfirmation = true;
  }
  validCalculationTier = false;
  get isShipmentIdValid() {
    if (
      this.form.commissionBookingSource === "pos" &&
      this.form.commissionFor === "shipment"
    ) {
      return this.form.commissionShipmentId.length > 0;
    }
    return true;
  }
  get isCommissionPriceTierReferenceValid() {
    if (this.form.commissionTierBase === "chargeable_weight") {
      return true;
    }
    return this.form.commissionPriceTierReference;
  }
  get isCommissionQuantifierAndPriceReferenceValid() {
    if (
      this.form.commissionType === "per_kg" ||
      this.form.commissionType === "flat"
    ) {
      return true;
    }
    return this.form.commissionQuantifier && this.form.commissionPriceReference;
  }
  get isFormValid() {
    return (
      this.form.commissionProduct &&
      this.form.commissionOriginCity &&
      this.form.commissionDestinationCity &&
      this.form.commissionTierBase &&
      this.form.commissionType &&
      this.form.commissionFor &&
      this.isCommissionQuantifierAndPriceReferenceValid &&
      this.isCommissionPriceTierReferenceValid &&
      this.isShipmentIdValid &&
      this.form.commissionBookingSource &&
      this.form.commissionCommodity &&
      this.validCalculationTier
    );
  }
  async saveBookingCommission() {
    this.closeSaveConfirmation();
    await BookingCommissionController.addBookingCommission();
  }
  get form() {
    return BookingCommissionController.bookingCommissionForm;
  }
  get commissionForName() {
    return COMMISSION_FOR_OPTIONS.find((item: any) => {
      return item.value == this.form.commissionFor;
    })?.name;
  }
}
