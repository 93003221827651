/* eslint-disable @typescript-eslint/camelcase */
import { toFormDataUtils } from "@/app/infrastructures/misc/Utils";
import { BulkBookingCommissionRequestInterface } from "../contracts/BulkBookingCommissionRequest";

export class BulkBookingCommissionCreate
  implements BulkBookingCommissionRequestInterface {
  tier_base: string;
  tier_price_reference: string;
  commission_price_reference: string;
  commission_type: string;
  commission_quantifier: string;
  booking_source: string;
  applied_to: string;
  shipment_prefix: string;
  tier_calculation: string;
  description: string;
  activity_name: string;
  attachment: any;
  constructor(
    tier_base: string,
    tier_price_reference: string,
    commission_price_reference: string,
    commission_type: string,
    commission_quantifier: string,
    booking_source: string,
    applied_to: string,
    shipment_prefix: string,
    tier_calculation: string,
    description: string,
    activity_name: string,
    attachment: any
  ) {
    this.tier_base = tier_base;
    this.tier_price_reference = tier_price_reference;
    this.commission_price_reference = commission_price_reference;
    this.commission_type = commission_type;
    this.commission_quantifier = commission_quantifier;
    this.booking_source = booking_source;
    this.applied_to = applied_to;
    this.shipment_prefix = shipment_prefix;
    this.tier_calculation = tier_calculation;
    this.description = description;
    this.activity_name = activity_name;
    this.attachment = attachment;
  }
  toFormData(): FormData {
    const data = {
      tier_base: this.tier_base,
      tier_price_reference: this.tier_price_reference,
      commission_price_reference: this.commission_price_reference,
      commission_type: this.commission_type,
      commission_quantifier: this.commission_quantifier,
      booking_source: this.booking_source,
      applied_to: this.applied_to,
      shipment_prefix: this.shipment_prefix,
      tier_calculation: this.tier_calculation,
      description: this.description,
      activity_name: this.activity_name,
      attachment: this.attachment
    };
    return toFormDataUtils(data);
  }
}

export class TierCalculation {
  tier_base_start: number;
  tier_base_end: number;
  tier_base_commission_amount: number;
  tier_base_branch_commission_amount: number;
  tier_base_pickup_commission_amount: number;
  tier_base_pickup_publish_rate_commission_amount: number;
  constructor(
    tier_base_start: number,
    tier_base_end: number,
    tier_base_commission_amount: number,
    tier_base_branch_commission_amount: number,
    tier_base_pickup_commission_amount: number,
    tier_base_pickup_publish_rate_commission_amount: number
  ) {
    this.tier_base_start = tier_base_start;
    this.tier_base_end = tier_base_end;
    this.tier_base_commission_amount = tier_base_commission_amount;
    this.tier_base_branch_commission_amount = tier_base_branch_commission_amount;
    this.tier_base_pickup_commission_amount = tier_base_pickup_commission_amount;
    this.tier_base_pickup_publish_rate_commission_amount = tier_base_pickup_publish_rate_commission_amount;

  }
}
