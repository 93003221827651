
import { Vue, Options } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import ComponentSelectShipmentId from "@/app/ui/views/commission-configuration/booking-commission/booking-commission-form/component-select-shipment-id.vue";
import { BookingCommissionController } from "@/app/ui/controllers/BookingCommissionControllers";
import debounce from "lodash/debounce";
import { LocationController } from "@/app/ui/controllers/LocationController";
import { BookingController } from "@/app/ui/controllers/BookingController";
import { CommodityController } from "@/app/ui/controllers/CommodityController";
import {
  COMMISSION_FOR_OPTIONS,
  COMMISSION_FOR_CLIENT_OPTIONS,
  COMMISSION_BOOKING_SOURCE
} from "@/app/ui/views/commission-configuration/booking-commission/BookingCommissionUtils";
import { RequestListCommodity } from "@/data/payload/api/CommodityApiRequest";

@Options({
  components: {
    Title,
    ComponentSelectShipmentId
  },
  props: {
    formType: {
      default: "add",
      type: String
    }
  }
})
export default class BookingCommissionFormCriteriaBooking extends Vue {
  props: any = this.$props;
  get form() {
    return BookingCommissionController.bookingCommissionForm;
  }
  get isInputDisabled() {
    return this.props.formType === "edit";
  }

  // booking source
  get bookingSourceOptions() {
    return COMMISSION_BOOKING_SOURCE;
  }
  isOpenSelectBookingSource = false;
  onOpenSelectBookingSource() {
    this.isOpenSelectBookingSource = true;
  }
  onCloseSelectBookingSource() {
    this.isOpenSelectBookingSource = false;
  }
  onSelectBookingSource(name: string, value: string) {
    this.form.commissionBookingSource = value;
    this.form.commissionFor = "";
    this.onCloseSelectBookingSource();
  }

  // commission for
  get commissionForOptions() {
    if (this.form.commissionBookingSource === "pos") {
      return COMMISSION_FOR_OPTIONS;
    }
    return COMMISSION_FOR_CLIENT_OPTIONS;
  }
  isOpenSelectCommissionFor = false;
  onOpenSelectCommissionFor() {
    this.isOpenSelectCommissionFor = true;
  }
  onCloseSelectCommissionFor() {
    this.isOpenSelectCommissionFor = false;
  }
  onSelectCommissionFor(name: string, value: string) {
    this.form.commissionFor = value;
    this.form.commissionPriceTierReference = "";
    this.form.commissionPriceReference = "";
    this.form.commissionShipmentId = [];
    this.onCloseSelectCommissionFor();
  }

  // origin & destination city
  fetchCity(search: string) {
    LocationController.getCityList({
      search: search,
      status: "active",
      page: 1,
      limit: 10
    });
  }
  filterCity = debounce((search: string) => {
    if (search.length > 2 || !search) this.fetchCity(search);
  }, 250);
  get cityOptions() {
    return [
      {
        id: 0,
        name: "All",
        value: "all"
      },
      ...LocationController.cityData.cityData.map(e => ({
        id: e.id,
        name: `${e.code} - ${e.name}`,
        value: `${e.code}`
      }))
    ];
  }
  get isLoadingCity() {
    return LocationController.isLoading;
  }

  // product
  fetchProduct(search: string) {
    BookingController.getProductsDelivery({
      search: search,
      page: 1,
      limit: 10,
      status: "active"
    });
  }
  filterProduct = debounce((search: string) => {
    this.fetchProduct(search);
  }, 250);
  get productOptions() {
    return [
      {
        id: 0,
        name: "All"
      },
      ...BookingController.productsDelivery.data.map((e: any) => ({
        id: e.id,
        name: e.name
      }))
    ];
  }
  get isLoadingProduct() {
    return BookingController.loadingProductsDelivery;
  }

  // commodities
  fetchCommodity(search: string) {
    CommodityController.getCommodityList(
      new RequestListCommodity({
        search: search,
        commodityStatus: "active"
      })
    );
  }
  filterCommodity = debounce((search: string) => {
    this.fetchCommodity(search);
  }, 250);
  get commodityOptions() {
    return [
      {
        id: 0,
        name: "All",
        code: "all"
      },
      ...CommodityController.commodities.data.map(item => {
        return {
          id: item.commodity_id,
          name: item.commodity_name,
          code: item.commodity_code
        };
      })
    ];
  }
  get isLoadingCommodity() {
    return CommodityController.isLoading;
  }

  get isShipmentIdMandatory() {
    return (
      (this.form.commissionShipmentId.length > 0 ||
        this.props.formType === "add") &&
      this.form.commissionBookingSource === "pos" &&
      this.form.commissionFor === "shipment"
    );
  }
}
