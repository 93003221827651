
import { Options, Vue } from "vue-class-component";
import {
  BOOKING_COMMISSION,
  DTPOL_CONFIG,
  PROGRESSIVE_COMMISSION_CONFIG
} from "@/app/infrastructures/misc/RolePermission";
import { checkRolePermission } from "@/app/infrastructures/misc/Utils";

@Options({
  components: {}
})
export default class Client extends Vue {
  // Tabs
  tabs = [
    {
      name: "commission-configuration-booking",
      title: "Booking",
      permission: BOOKING_COMMISSION.ENABLE
    },
    {
      name: "commission-configuration-bulk-booking",
      title: "Bulk Upload",
      permission: BOOKING_COMMISSION.ENABLE
    },
    {
      name: "commission-configuration-handling",
      title: "Handling",
      permission: "not-allowed"
    },
    {
      name: "commission-configuration-delivery-tiering-policy",
      title: "DTPOL",
      permission: DTPOL_CONFIG.VIEW
    },
    {
      name: "commission-configuration-progressive-commisson",
      title: "Komisi Progressive",
      permission: PROGRESSIVE_COMMISSION_CONFIG.ENABLE
    }
  ];

  get filteredTabs() {
    return this.tabs.filter((tab: any) => {
      return checkRolePermission(tab.permission);
    });
  }

  changeTabs(value: string) {
    this.$router.push({
      name: value
    });
  }

  isTabActive(name: string) {
    return name === this.$route.name;
  }
}
