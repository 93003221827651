import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "relative bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen w-full" }
const _hoisted_2 = { class: "flex flex-col justify-between items-start relative pt-8 pb-2" }
const _hoisted_3 = { class: "flex flex-row align-middle items-center justify-between w-full" }
const _hoisted_4 = { class: "text-20px text-black-lp-200 flex flex-row items-center flex-no-wrap relative" }
const _hoisted_5 = {
  key: 2,
  class: "mb-16 overflow-auto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdvancedFilter = _resolveComponent("AdvancedFilter")!
  const _component_lp_button = _resolveComponent("lp-button")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_TableV2 = _resolveComponent("TableV2")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode("div", _hoisted_4, [
          _createVNode(_component_AdvancedFilter, {
            "custom-max-height": "60vh",
            "model-value": _ctx.advancedFilterData,
            bookingSource: "",
            appliedTo: "",
            shipmentPrefix: "",
            isCityOrigin: "",
            isCityDestination: "",
            productBookingShipment: "",
            ableProductAll: true,
            ableOriginAll: true,
            ableDestinationAll: true,
            ableAll: true,
            commodity: "",
            isScroll: true,
            isDisabled: false,
            titleProductCustom: "Produk",
            appliedToList: _ctx.appliedToListData,
            onChange: _ctx.onChangeAdvancedFilter,
            onOpened: _ctx.onOpenedAdvancedFilter,
            onReset: _ctx.onResetAdvancedFilter
          }, null, 8, ["model-value", "appliedToList", "onChange", "onOpened", "onReset"])
        ]),
        (_ctx.ableToCreate)
          ? (_openBlock(), _createBlock(_component_lp_button, {
              key: 0,
              onClick: _ctx.goToCreate,
              title: "Buat Komisi Booking",
              textColor: "white"
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.isError && !_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_ErrorMessage, {
          key: 0,
          errorType: _ctx.errorCause,
          onTryAgain: _ctx.onRequest
        }, null, 8, ["errorType", "onTryAgain"]))
      : (
        _ctx.bookingCommissionList.length === 0 &&
          !_ctx.isLoading &&
          !_ctx.isError &&
          !_ctx.isAdvancedFilterData
      )
        ? (_openBlock(), _createBlock(_component_EmptyState, {
            key: 1,
            customMessage: "Oops.. Belum ada data yang dapat ditampilkan"
          }))
        : (_openBlock(), _createBlock("div", _hoisted_5, [
            _createVNode(_component_TableV2, {
              class: "w-full",
              columns: _ctx.columns,
              data: _ctx.bookingCommissionList,
              loading: _ctx.isLoading,
              onRequest: _ctx.fetchBookingCommissionList,
              errorCause: _ctx.errorCause,
              isDetailAble: _ctx.ableToViewDetail,
              onClick: _ctx.onClickRow,
              pagination: _ctx.pagination,
              "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => (_ctx.pagination = $event)),
              isRightFreeze: true,
              pinnedSubstractHeight: "290px",
              totalColumnFreeze: 0,
              paginationStyle: "v3"
            }, null, 8, ["columns", "data", "loading", "onRequest", "errorCause", "isDetailAble", "onClick", "pagination"])
          ]))
  ]))
}