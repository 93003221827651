
import { Vue, Options } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import { BulkBookingCommissionController } from "@/app/ui/controllers/BulkBookingCommissionController";
import { COMMISSION_BOOKING_SOURCE } from "@/app/ui/views/commission-configuration/booking-commission/BookingCommissionUtils";

@Options({
  components: {
    Title
  }
})
export default class DetailCriteriaBooking extends Vue {
  get detailData() {
    return BulkBookingCommissionController.bulkBookingCommissionDetail;
  }

  get commissionBookingSource() {
    if (!this.detailData.commissionBookingSource) {
      return "";
    }
    return COMMISSION_BOOKING_SOURCE.find((item: any) => {
      return item.value == this.detailData.commissionBookingSource;
    })?.name;
  }
}
