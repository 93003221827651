import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "relative bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen w-full" }
const _hoisted_2 = { class: "flex flex-col justify-between items-start relative pt-8 pb-2" }
const _hoisted_3 = { class: "flex flex-row align-middle items-center justify-between w-full" }
const _hoisted_4 = { class: "text-20px text-black-lp-200 flex flex-row items-center flex-no-wrap relative" }
const _hoisted_5 = { class: "flex" }
const _hoisted_6 = {
  key: 1,
  class: "mb-16 overflow-auto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Select = _resolveComponent("Select")!
  const _component_lp_button = _resolveComponent("lp-button")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_TableV2 = _resolveComponent("TableV2")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode("div", _hoisted_4, [
          _createVNode(_component_Select, {
            dataSelect: _ctx.statusData,
            onOpenSelect: _ctx.onOpenStatusSelect,
            onCloseSelect: _ctx.onCloseStatusSelect,
            onChooseSelect: _ctx.onSelectStatus,
            value: _ctx.statusName,
            isOpenMenu: _ctx.statusSelect,
            isDefaultBlack: "",
            class: "text-12px xxl:text-14px my-2 z-20",
            style: {"width":"12rem"},
            longTrim: 30
          }, null, 8, ["dataSelect", "onOpenSelect", "onCloseSelect", "onChooseSelect", "value", "isOpenMenu"])
        ]),
        _createVNode("div", _hoisted_5, [
          _createVNode(_component_lp_button, {
            onClick: _ctx.downloadTemplate,
            title: "Download Template",
            icon: "download-gray",
            textColor: "gray-lp-300",
            color: "gray-lp-400",
            outline: "",
            class: "mr-4"
          }, null, 8, ["onClick"]),
          _createVNode(_component_lp_button, {
            onClick: _ctx.goToCreate,
            title: "Upload Berkas",
            textColor: "white"
          }, null, 8, ["onClick"])
        ])
      ])
    ]),
    (_ctx.bulkBookingCommissionList.length === 0 && !_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_EmptyState, {
          key: 0,
          customMessage: "Oops.. Belum ada data yang dapat ditampilkan"
        }))
      : (_openBlock(), _createBlock("div", _hoisted_6, [
          _createVNode(_component_TableV2, {
            "border-top": false,
            "border-bottom": false,
            columns: _ctx.columns,
            data: _ctx.bulkBookingCommissionList,
            loading: _ctx.isLoading,
            onRequest: _ctx.fetchBulkBookingCommissionList,
            errorCause: _ctx.errorCause,
            isDetailAble: _ctx.ableToViewDetail,
            onClick: _ctx.onClickRow,
            pagination: _ctx.pagination,
            "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => (_ctx.pagination = $event))
          }, null, 8, ["columns", "data", "loading", "onRequest", "errorCause", "isDetailAble", "onClick", "pagination"])
        ]))
  ]))
}