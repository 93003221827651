import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_booking_commission_detail_criteria_booking = _resolveComponent("booking-commission-detail-criteria-booking")!
  const _component_booking_commission_detail_commission_rules = _resolveComponent("booking-commission-detail-commission-rules")!
  const _component_booking_commission_detail_tier_calculation = _resolveComponent("booking-commission-detail-tier-calculation")!
  const _component_Chips = _resolveComponent("Chips")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _component_CreateUpdate = _resolveComponent("CreateUpdate")!
  const _component_lp_button = _resolveComponent("lp-button")!
  const _component_DetailLayout = _resolveComponent("DetailLayout")!

  return (_openBlock(), _createBlock(_component_DetailLayout, {
    scrollSidebar: "",
    customClass: "py-4",
    onBack: _ctx.goBack,
    title: _ctx.title,
    loading: _ctx.isLoading,
    error: _ctx.isError,
    errorType: _ctx.errorCause,
    onTryAgain: _ctx.getDetail
  }, {
    data: _withCtx(() => [
      _createVNode(_component_booking_commission_detail_criteria_booking),
      _createVNode(_component_booking_commission_detail_commission_rules),
      _createVNode(_component_booking_commission_detail_tier_calculation)
    ]),
    "sub-data": _withCtx(() => [
      _createVNode(_component_DataWrapper, { label: "Status" }, {
        default: _withCtx(() => [
          _createVNode(_component_Chips, {
            class: "mt-1.5",
            label: _ctx.detailData.commissionStatus,
            textColor: 
            _ctx.detailData.commissionStatus.toLowerCase() === 'active'
              ? 'green-lp-200'
              : 'red-lp-200'
          ,
            color: 
            _ctx.detailData.commissionStatus.toLowerCase() === 'active'
              ? 'green-lp-300'
              : 'red-300'
          ,
            rounded: ""
          }, null, 8, ["label", "textColor", "color"])
        ]),
        _: 1
      }),
      _createVNode(_component_CreateUpdate, {
        createdAt: _ctx.detailData.commissionCreatedAt,
        createdBy: _ctx.detailData.commissionCreatedBy,
        updatedAt: _ctx.detailData.commissionUpdatedAt,
        updatedBy: _ctx.detailData.commissionUpdatedBy
      }, null, 8, ["createdAt", "createdBy", "updatedAt", "updatedBy"]),
      (_ctx.isAbleToEdit)
        ? (_openBlock(), _createBlock(_component_lp_button, {
            key: 0,
            class: "w-3/12",
            "text-color": "white",
            customClass: "w-full py-2.5",
            onClick: _ctx.goToEdit,
            title: "Ubah"
          }, null, 8, ["onClick"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["onBack", "title", "loading", "error", "errorType", "onTryAgain"]))
}